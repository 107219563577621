// src/components/AboutUs.jsx
import React from "react";
// import aboutImage1 from "../assets/images/gallery/about1.png"; // Import image if it's in src folder
// import aboutImage2 from "../assets/images/gallery/about2.png"; // Import image if it's in src folder
import aboutImage1 from "../assets/images/new_images/AboutUs-1.webp";
import '../assets/css/Newstyling.css';


const AboutUsSection = () => {
  return (
    <section className="about-area section-padding2  bg_section ">
      <div className="section-tittle text-center mb-100">
              <h2>Welcome to Alzevita</h2>
              <span>Alzevita</span>
            </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="about-caption mb-40">
              {/* Section Title */}
              {/* <div className="section-tittle section-tittle2 mb-35 text-center">
                <span>About Us</span>
                <h2>Welcome To Alzevita</h2>
              </div> */}
              <p>Alzevita is dedicated to empowering early detection and monitoring of Alzheimer's disease through advanced technology. Alzheimer’s is a progressive neurodegenerative disorder that primarily impacts memory, thinking, and behavior. Early identification is essential for managing the disease and slowing its progression, enabling patients and families to plan and receive tailored care.</p>
              <p>The hippocampus, a vital region for memory, is often among the first areas of the brain affected by Alzheimer’s. Accurate segmentation and measurement of its volume are key for precise diagnosis and tracking. At Alzevita, we utilize state-of-the-art automated hippocampus segmentation, providing clinicians with consistent, objective assessments to support early diagnosis and continuous monitoring.</p>
              <p>Our innovative approach enables healthcare providers to deliver personalized treatments that meet each patient’s unique needs. With Alzevita, we aim to make a meaningful difference in the journey of Alzheimer’s care.</p>
              {/* <p>The hippocampus, a vital region for memory, is often among the first areas of the brain affected by Alzheimer’s. Accurate segmentation and measurement of its volume are key for precise diagnosis and tracking. At Alzevita, we utilize state-of-the-art automated hippocampus segmentation, providing clinicians with consistent, objective assessments to support early diagnosis and continuous monitoring.
              </p>
              <p>Our innovative approach enables healthcare providers to deliver personalized treatments that meet each patient’s unique needs. With Alzevita, we aim to make a meaningful difference in the journey of Alzheimer’s care.
              </p> */}
              {/* <div className="about-btn1 mb-30">
                <a href="about.html" className="btn about-btn">
                  Find Doctors .<i className="ti-arrow-right"></i>
                </a>
              </div>
              <div className="about-btn1 mb-30">
                <a href="about.html" className="btn about-btn2">
                  Appointment <i className="ti-arrow-right"></i>
                </a>
              </div>
              <div className="about-btn1 mb-30">
                <a href="about.html" className="btn about-btn2">
                  Emergency 1 <i className="ti-arrow-right"></i>
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-5 col-md-13 ">
            <div>
            <img src={aboutImage1} alt="About us" className="img-fluid rounded-4 " />
            </div>
            {/* About Images */}
            {/* <div className="about-img">
              <div className="about-font-img d-none d-lg-block">
                <img src={aboutImage1} alt="About 2" className="img-fluid rounded-4 mx-4 mt-40" />
              </div>
              <div className="about-back-img">
                 <img src={aboutImage1} alt="About 2" /> 
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
