import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { Button } from '@mui/material';
import debounce from 'lodash.debounce';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import API_BASE_URL from './config';
import UserProfileData from './UserProfileData';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const columns = (handleView, handleToggleStatus, handleDelete) => [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'profession', headerName: 'Profession', width: 200 },
    { field: 'contactNumber', headerName: 'Contact Number', width: 150 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'createdDate', headerName: 'Created Date', width: 160 },
    { field: 'updatedDate', headerName: 'Updated Date', width: 160 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params) => (
            <div className="d-flex justify-content-center mt-2">
                <Tooltip title="View" arrow placement="top">
                    <button type="button" className="btn btn-sm btn-light-primary w-35px h-35px ms-2" onClick={() => handleView(params.row)} >
                        <i className="fa fa-eye fs-6"></i>
                    </button>
                </Tooltip>
                <Tooltip title="Disable" arrow placement="top">
                    <button type="button" className={`btn btn-sm w-35px h-35px ms-2 ${params.row.status === 'ACTIVE' ? 'btn-light-danger' : 'btn-light-success'}`} onClick={() => handleToggleStatus(params.row)} >
                        <i className={`fa ${params.row.status === 'ACTIVE' ? 'fa-times' : 'fa-check'} fs-6`}></i>
                    </button>
                </Tooltip>
                <Tooltip title="Delete" arrow placement="top">
                    <button type="button" className="btn btn-sm btn-light-danger w-35px h-35px ms-2" >
                        <i className="fa fa-trash fs-6"></i>
                    </button>
                </Tooltip>
            </div>
        ),
    },
];

function AdminDataTable() {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalType, setModalType] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleView = (row) => {
        setSelectedUserId(row.id);
        setModalType('userProfileData');
    };
    const handleToggleStatus = async (row) => {
        const apitoken = localStorage.getItem('Token');

        if (!apitoken) {
            console.error('No API token found in local storage');
            return;
        }

        try {
            const newStatus = row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
            await axios.patch(`${API_BASE_URL}/mri-users/update-user`, {
                id: row.id,
                status: newStatus
            }, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                }
            });

            setRows(prevRows => prevRows.map(item =>
                item.id === row.id ? { ...item, status: newStatus } : item
            ));
        } catch (error) {
            console.error('There was an error updating the status!', error);
        }
    };

    const handleDelete = async (row) => {
        const apitoken = localStorage.getItem('Token');

        if (!apitoken) {
            console.error('No API token found in local storage');
            return;
        }

        try {
            await axios.delete(`${API_BASE_URL}/mri-users/delete-user-id/${row.id}`, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                }
            });

            toast.success('Enhance Data Deleted Successfully');
            setRows(prevRows => prevRows.filter(item => item.id !== row.id));
        } catch (error) {
            console.error('There was an error deleting the user!', error);
        }
    };


    const fetchData = async () => {
        const apitoken = localStorage.getItem('Token');

        if (!apitoken) {
            console.error('No API token found in local storage');
            return;
        }

        try {
            const response = await axios.get(`${API_BASE_URL}/mri-users/details`, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                },
                params: {
                    page: page,
                    size: pageSize,
                }
            });

            const { data, totalPages } = response.data;

            const mappedData = data.map(item => ({
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                profession: item.profession,
                contactNumber: item.contactNumber,
                status: item.status,
                createdDate: new Date(item.createdDate).toLocaleDateString(),
                updatedDate: new Date(item.updatedDate).toLocaleDateString(),
            }));
            setRows(mappedData);
            setTotalPages(totalPages);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, pageSize]);

    const handleChangePageSize = (event) => {
        const newPageSize = parseInt(event.target.value);
        setPageSize(newPageSize);
        setPage(0);
    };

    const navigateToPage = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleFirstPage = () => {
        setPage(0);
    };

    const handlePrevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
        }
    };

    const handleLastPage = () => {
        setPage(totalPages - 1);
    };

    const handleSearch = async () => {
        const apitoken = localStorage.getItem('Token');
        try {
            const response = await fetch(`${API_BASE_URL}/mri-users/search?search=${searchTerm}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apitoken}`,
                },
            });
            const result = await response.json();
            if (response.ok) {
                console.log("Search results:", result);
                setRows(result.content);
                setTotalPages(result.totalElements);
            } else {
                console.error("Failed to fetch search results:", result);
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    const debouncedSearch = debounce(handleSearch, 300);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch();
    };

    const handleClose = () => {
        setModalType(null);
        setSelectedUser(null);
    };

    return (
        <>
            <div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <div className="d-flex">
                            <div>
                                <Button disabled={page === 0} onClick={handleFirstPage}>First</Button>
                                <Button disabled={page === 0} onClick={handlePrevPage}>Previous</Button>
                            </div>
                            <div>
                                <select className="form-select border-primary-subtle w-md-80px" aria-label="Default select example" onChange={handleChangePageSize} value={pageSize}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                </select>
                            </div>
                            <div>
                                <Button disabled={page >= totalPages - 1} onClick={handleNextPage}>Next</Button>
                                <Button disabled={page >= totalPages - 1} onClick={handleLastPage}>Last</Button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <input className="input-search" type="text" placeholder="Search" onChange={handleInputChange} />
                    </div>
                </div>

                <DataGrid
                    rows={rows}
                    columns={columns(handleView, handleToggleStatus, handleDelete)}
                    page={page}
                    pagination
                    paginationMode="client"
                    className="rounded-2 data-table-container" // style={{minHeight: '500px', maxHeight: '500px'}}
                />
            </div>

            <Modal show={modalType === 'userProfileData'} onHide={handleClose} dialogClassName="modal-lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUserId && <UserProfileData userId={selectedUserId} />}
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default AdminDataTable;
