import Login from '../Login';
import Signup from '../Signup';
import ForgetPassword from '../ForgetPassword';
import ResetPassword from '../ResetPassword';
import LandingPage from '../LandingPage';
import OtpVerification from '../OtpVerification';
import Home from '../Home';
import Alzheimer from '../Alzheimer';
import EnhanceUserHistory from '../EnhanceUserHistory';
import Admin from '../Admin';
import EnhanceDataTable from '../EnhanceDataTable';
import Enhance from '../Enhance';
import EnhanceView from '../EnhanceView';
import AlzheimerMain from '../AlzheimerMain';
import AlzheimerView from '../AlzheimerView';
import AlzheimerUserHistory from '../AlzheimerUserHistory';
import AdminDataTable from '../AdminDataTable';
import AlzheimerDataTable from '../AlzheimerDataTable';
import ChangePassword from '../ChangePassword';
import AboutUs from '../AboutUs';
import ContactUs from '../ContactUs';
import Radiologist from '../Radiologist';
import TermsAndConditions from '../Terms_and_Conditions';


const authRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
  { path: "/forgetpassword", component: ForgetPassword },
  { path: "/", component: LandingPage },
  { path: "/resetpassword/*", component: ResetPassword },
  { path: "/otpverify", component: OtpVerification },
  { path: "/home", component: Home, protected: true },
  { path: "/alzheimer", component: Alzheimer, protected: true },
  { path: "/enhanceuserhistory", component: EnhanceUserHistory, protected: true },
  { path: "/alzheimeruserhistory", component: AlzheimerUserHistory, protected: true },
  { path: "/admin", component: Admin, protected: true },
  { path: "/enhancedatatable", component: EnhanceDataTable, protected: true },
  { path: "/enhance", component: Enhance, protected: true },
  { path: "/enhanceview/*", component: EnhanceView, protected: true },
  { path: "/alzheimermain", component: AlzheimerMain, protected: true },
  { path: "/alzheimerview/*", component: AlzheimerView, protected: true },
  { path: "/admindatatable", component: AdminDataTable, protected: true },
  { path: "/alzheimerdatatable", component: AlzheimerDataTable, protected: true },
  { path: "/changepassword", component: ChangePassword, protected: true },
  {path: '/AboutUs',component: AboutUs,protected: false, },
  {path: '/ContactUs', component: ContactUs, protected: false, },
  {path: '/Radiologist',component: Radiologist,protected: false,},
  { path: '/Terms_and_Conditions', component: TermsAndConditions, protected: false,},

];


export default authRoutes;
