import React from "react";
// import logo from "../assets/images/logo/Topia Life Science-white.png"; 
import { Link} from "react-router-dom";
import '/src/assets/css/Newstyling.css';
import Alzevitalogo from '../assets/images/logo/Alzevitalogobw.webp';
import Topialogo from '../assets/images/logo/Topia-Life-Sciences.png';

const NewFooter = () => {
    return (
        <footer>
            {/* Footer Start */}
            <div className="footer-area section-bg" style={{ backgroundImage: `url('assets/img/gallery/footer_bg.jpg')` }} > 
            
                <div className="container">
                    <div className="footer-top footer-padding">
                        <div className="row d-flex justify-content-between mb-0">
                            <div className=" col-md-4 col-sm-8">

                                <div className="mb-4">
                                    <a href="https://alzevita.com/">
                                        <img src={Alzevitalogo} width="220" alt="Logo" />
                                        {/* <img src={logo} alt="Logo" />  */}
                                    </a>
                                </div>
                                <div className="mb-3">
                                    <div className="linkd">
                                        <p className=" fs-3z mb-1 d-inline">Follow us on : </p>

                                        <a href="https://www.linkedin.com/company/alzevita/posts/?feedView=all" className="">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" width="30px"
                                                    height="30px">
                                                    <path fill="#0288D1"
                                                        d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z" />
                                                    <path fill="#FFF"
                                                        d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="linkd">
                                    <p className="fs-4 mb-1"><strong>Headquarters : United Kingdom</strong></p>
                                    <p className="fs-4">128 City Road, London, United Kingdom, EC1V 2NX</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 pt-4">
                                <div className="single-footer-caption mb-50 linkd">
                                    <div className="mb-4">
                                        <p className="fs-4 mb-2">Mail us at :</p>
                                        <a href="mailto:info@topialifesciences.com" className="fs-4 link" target="_blank">info@topialifesciences.com</a>
                                        
                                    </div>
                                    <div clasNames="mb-4">
                                        <p className="fs-4 mb-1">Visit our website :</p>
                                        <a href="https://topialifesciences.com/" target="_blank" className="fs-4 link" >www.topialifesciences.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 ">
                                {/* single-footer-caption  */}
                                <div className="mb-30 modal-content rounded-4">
                                    <div className="footer-number mb-6 modal-body">
                                        <div className="">
                                            <p className="fs-2 mb-2">Disclaimer</p>
                                        </div>
                                        <div>
                                            <p class=" mb-0" style={{ fontSize: '13px' }}>Alzevita is not yet cleared by the US Food and Drug Administration (FDA). It is not available for sale in the United States until such clearance has been obtained. Submission for FDA 510(k) clearance is pending.</p>
                                        </div>
                                    </div>
                                    {/* Form */}
                                    {/* <div className="footer-form">
                                        <div id="mc_embed_signup">
                                            <form
                                                target="_blank"
                                                action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                                method="get"
                                                className="subscribe_form relative mail_part"
                                                noValidate="true"
                                            >
                                                <input
                                                    type="email"
                                                    name="EMAIL"
                                                    id="newsletter-form-email"
                                                    placeholder=" Email Address "
                                                    className="placeholder hide-on-focus"
                                                    onFocus={(e) => (e.target.placeholder = "")}
                                                    onBlur={(e) => (e.target.placeholder = "Your email address")}
                                                />
                                                <div className="form-icon">
                                                    <button
                                                        type="submit"
                                                        name="submit"
                                                        id="newsletter-submit"
                                                        className="email_icon newsletter-submit button-contactForm"
                                                    >
                                                        Send
                                                    </button>
                                                </div>
                                                <div className="mt-10 info"></div>
                                            </form>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className=" d-flex ">
                            <div className=" col-lg-4 pl-0">
                                <div className="d-flex mb-3 pre_ce">
                                    <div className="me-md-4 me-2">
                                        <Link to="/Terms_and_Conditions" target="_blank" className="fs-4 link"><p>Terms and Conditions</p></Link>
                                    </div>
                                    <span>|</span>
                                    <div className="ms-md-4 ms-2">
                                        <a href="privacyPolicy" target="_blank" className=" fs-4 link"><p>Privacy & Policy</p></a>
                                    </div>
                                </div>
                                {/* <div className="footer-social f-right">
                                    <a href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="https://www.facebook.com/sai4ull">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#">
                                        <i className="fas fa-globe"></i>
                                    </a>
                                    <a href="#">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </div> */}
                            </div>
                            <div className=" col-lg-8">
                                <div className="footer-copy-right text-end">
                                    <p>
                                        {/* Copyright &copy; {new Date().getFullYear()}  */}
                                        Alzevita @2024 - Developed By :
                                        <img src={Topialogo} alt="Topia.logo" className="logo-img"></img>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer End */}
        </footer>
    );
};

export default NewFooter;
