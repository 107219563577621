
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import authRoutes from './routes/allroutes'; // Adjust the path if your `allroutes.js` is elsewhere
import AuthGuard from './AuthGuard'; 
//  import './assets/css/bootstrap.css';
//  import './assets/css/responisve.css';
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              route.protected ? (
                <AuthGuard>
                  <route.component />
                </AuthGuard>
              ) : (
                <route.component />
              )
            }
          />
          
        ))}
        
      </Routes>
    </Router>
    
  );
}

export default App;
