import React, { useState, useEffect } from 'react';
import './assets/css/ForgetPassword.css';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [visible, setVisible] = useState(true);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  const navigateToLogin = () => {
    navigate('/login');
  };

  const validateEmail = () => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setEmailError('Email is required');
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordLoading(true);
    setVisible(true);
    const isEmailValid = validateEmail();

    if (!isEmailValid) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/mri-users/forgetpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), 
      });
    
      if (response.ok) {
        const responseBody = await response.json(); 
        if (responseBody.msg === "Password sent to email") { 
          toast.success('An email with instructions to reset your password has been sent.');
          setPasswordLoading(false);
          setVisible(false);
          navigate('/login');
        } else {
          toast.error('User Not Found');
          console.error('Unexpected response from server');
        }
      } else {
        toast.error('Failed to send email. Please try again later.');
        console.error('Failed to send email');
      }
    } catch (error) {
      toast.error('An error occurred while processing your request. Please try again later');
      console.error('Error:', error);
    } finally{
      setPasswordLoading(false);
      setVisible(false);
    }
  };

  return (
    <div>
      <div className="forgetPassword-bg"></div>
      <div className="container forgot-form-container">
        <div className="p-md-5 p-3 w-lg-45 rounded-4" id="form-card">
          <h1 className="text-center mb-2 text-light">Forget Password</h1>
          <p className="text-center mb-4 fs-5 text-light">Recover your account in easy steps.</p>
          <div className="">
            <form className="forget-password-container">
              <div className="row">
                <div className="col-12">
                  <div className="mb-4">
                    <div className="form-group">
                      <label htmlFor="email" className="form-label text-light">Email:</label>
                      <input type="email" className="form-control rounded-3" id="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      {emailError && <div className="text-danger">{emailError}</div>}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <button onClick={navigateToLogin} className="btn btn-primary w-md waves-effect waves-light py-2">Back to Login</button>
                    <button type="submit" className="btn btn-primary w-md waves-effect waves-light py-2 w-25" onClick={handleSubmit}>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
};

export default ForgetPassword;
