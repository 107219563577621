import React,{useEffect } from 'react';
import NewHeader from './components/NewHeader';
import NewFooter from './components/NewFooter';
import '/src/assets/css/Newstyling.css';
import UKLogo from '/src/assets/flagIcons/united-kingdom.webp';
import USLogo from '/src/assets/flagIcons/united-states.webp';
import Indialogo from '/src/assets/flagIcons/flag.webp';
import Romanialogo from '/src/assets/flagIcons/romania.webp';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <main>
      <NewHeader/>
      {/* Hero Start */}
      <div className="slider-area2 cont_banner ">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}

      {/* Contact Section Start */}
      <section className="contact-section about-area ">
        <div className="container ">
          <div className="row ">
            <div className="col-12 py-3">
              <h2 className="contact-title">Get in Touch</h2>
            </div>

            {/* Contact Form */}

            {/* <div className="col-lg-8 ">
              <form
                className="form-contact contact_form"
                action="contact_process.php"
                method="post"
                id="contactForm"
                noValidate="novalidate"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        onFocus="this.placeholder = ''"
                        onBlur="this.placeholder = 'Enter your name'"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        onFocus="this.placeholder = ''"
                        onBlur="this.placeholder = 'Enter email address'"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        onFocus="this.placeholder = ''"
                        onBlur="this.placeholder = 'Enter Subject'"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        onFocus="this.placeholder = ''"
                        onBlur="this.placeholder = 'Enter Message'"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" className="button button-contactForm boxed-btn">
                    Send
                  </button>
                </div>
              </form>
            </div> */}
              <div className="col-lg-6 framing mb-40">
              <iframe aria-label='Contact Us(Alzevita) Form' frameborder="0" style={{height:'625px', width:'100%', border:'none',}} src='https://forms.zohopublic.in/TopiaLifeSciences/form/ContactUsAlzevitaForm/formperma/P5EHI2Do2SXxMQfi_1mqN-E8_6lRPMILp3pXvrOX8YI'></iframe>
              </div>
            {/* Contact Info */}
            <div className="col-lg-6 pl-80 ">
            <h2 class="fs-1  mb-30 ">Our Presence:</h2>
              <div className="media contact-info">
                <span className="contact-info__icon">
                    <img src={UKLogo} alt="" className="icon_image"></img>
                </span>
                <div className="media-body">
                  <h3>United Kingdom</h3>
                  <p>128 City Road, London, United Kingdom, EC1V 2NX</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                <img src={USLogo} alt="" className="icon_image"></img>
                </span>
                <div className="media-body">
                  <h3> United States</h3>
                  <p>1710 East Franklin Street #1137, Chapel Hill, NC 27514</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                <img src={Indialogo} alt="" className="icon_image"></img>
                </span>
                <div className="media-body">
                  <h3>India</h3>
                  <p>Meldi Estate, 22B, Railway Crossing Rd, near Gota, Gota, Ahmedabad, Gujarat 382481, India</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                <img src={Indialogo} alt="" className="icon_image"></img>
                </span>
                <div className="media-body">
                  <h3> India</h3>
                  <p>Siddarth Annexure 3, National Highway 8, beside Hotel Plazzo, Vemali, Vadodara, Gujarat 391740, India</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                <img src={Romanialogo} alt="" className="icon_image"></img>
                </span>
                <div className="media-body">
                  <h3> Romania</h3>
                  <p>Str. Panselelor, nr. 27-28,Brasov - 500419, Romania</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section End */}

      <NewFooter/>
    </main>
  );
};

export default ContactUs;
