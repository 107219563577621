import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './assets/css/Home.css';
import './assets/css/Alzheimer.css';
import './assets/dropzone/plugins.bundle.css';
import Footer from './Footer';
import NiiVue from './Niivue';
import NiiVueWrapper from './NiiVueWrapper';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import Swal from 'sweetalert2';

// header
import UserHeader from './UserHeader';

const Alzheimer = () => {

  const fileInputRef = useRef(null);
  const apitoken = localStorage.getItem('Token');
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnahnceVolumes] = useState([]);
  const [maskEnhanceVolumes, setMaskEnhanceVolumes] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [leftHippocampus, setLeftHippocampus] = useState('');
  const [rightHippocampus, setRightHippocampus] = useState('');
  const [isImageSegmented, setIsImageSegmented] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const navigate = useNavigate();
  console.log("token--------------" + apitoken);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/alzheimer-request/hippocampus`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${apitoken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch hippocampus data');
        }

        const data = await response.json();
        if (data.status === 0) {
          setLeftHippocampus(data.data.leftHippocampus);
          setRightHippocampus(data.data.rightHippocampus);
        } else {
          console.error('Failed to fetch hippocampus data:', data.msg);
        }
      } catch (error) {
        console.error('Error fetching hippocampus data:', error);
      }
    };

    fetchData();
  }, [apitoken]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/alzheimer-request/view-alzheimer/alzheimer.nii.gz?token=${apitoken}`,
      {
      }).then((response) => {
        if (response.status == 200) {
          let volumes = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-alzheimer/alzheimer.nii.gz?token=${apitoken}`,
            },
          ];
          setVolumes(volumes);
        }
      })
  }, [])

  useEffect(() => {
    fetch(`${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/enhanceAlzheimer.nii.gz?token=${apitoken}`,
      {
      }).then((response) => {
        if (response.status == 200) {
          let enhanceVolumes = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/enhanceAlzheimer.nii.gz?token=${apitoken}`,
            },
          ];
          setEnahnceVolumes(enhanceVolumes);
        }
      })
  }, [])

  useEffect(() => {
    fetch(`${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/maskEnhanceAlzheimer.nii.gz?token=${apitoken}`,
      {
      }).then((response) => {
        if (response.status == 200) {
          let maskEnhanceVolumes = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/maskEnhanceAlzheimer.nii.gz?token=${apitoken}`,
            },
          ];
          setMaskEnhanceVolumes(maskEnhanceVolumes);
        }
      })
  }, [])



  const handleImageUpload = async () => {
    if(volumes.length > 0){
     
      Swal.fire({
        icon: 'warning',
        title: 'Clear Image!',
        text: 'To proceed with a new image upload, please clear the current image.',
      });
    }else{
    if (fileInputRef.current && fileInputRef.current.files) {
      const uploaded = fileInputRef.current.files[0];
      setUploadedFile(uploaded);

      try {
        const formData = new FormData();
        formData.append('file', uploaded);

        const response = await fetch(`${API_BASE_URL}/alzheimer-request/upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apitoken}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        if (data.status === 200) {

          Swal.fire({
            icon: 'success',
            title: 'Uploaded!',
            text: 'File Uploaded Successfully',
          });

          const NVRVolumeImage = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-alzheimer/alzheimer.nii.gz?token=${apitoken}`,
            },
          ];
          setVolumes(NVRVolumeImage);
          document.getElementById('formFile').disabled = true;

          if (data.data && data.data.image) {
            const blob = b64toBlob(data.data.image, 'image/jpeg');
            const imageUrlFromBlob = URL.createObjectURL(blob);
            setImageURL(imageUrlFromBlob);
          } else {
            console.error('Image data is not available');
          }
        } else {
          console.error('Failed to fetch image URL');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'please select image to upload',
        text: 'File input reference is null or files array is empty',
      });
      console.error("File input reference is null or files array is empty");
    }
  }
  };

  useEffect(() => {
    setIsImageSegmented(enhanceVolumes.length > 0 && maskEnhanceVolumes.length > 0);
    setIsUpload(volumes.length > 0 && enhanceVolumes.length > 0 && maskEnhanceVolumes.length > 0)
  }, [volumes.length, enhanceVolumes.length, maskEnhanceVolumes.length]);

  const enhanceImage = () => {
    setIsUpload(true);
    setIsImageSegmented(true);
    fetch(`${API_BASE_URL}/alzheimer-request/enhance-alzheimer`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${apitoken}` },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to enhance image');
        }
        return response.json();
      })
      .then(responseData => {
        const parsedData = JSON.parse(responseData.data);
  
        if (parsedData.success === "Image Segment Successfully") {

          Swal.fire({
            icon: 'success',
            title: 'Image Segmented Successfully',
            text: 'Wait for a minute to see segmented images',
          });

          setLeftHippocampus(parsedData.left_hippocampus);
          setRightHippocampus(parsedData.right_hippocampus);
          const NVRVolumeEnhanceImage = [
            { url: `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/enhanceAlzheimer.nii.gz?token=${apitoken}` },
          ];
          setEnahnceVolumes(NVRVolumeEnhanceImage);
  
          const maskEnhanceVolumes = [
            { url: `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/maskEnhanceAlzheimer.nii.gz?token=${apitoken}` },
          ];
          setMaskEnhanceVolumes(maskEnhanceVolumes);
        } else {
          throw new Error('Image segmentation failed');
        }
      })
      .catch(error => {
        console.error('Error enhancing image:', error);
        Swal.fire({
          icon: 'error',
          title: 'Server Corrupted',
          text: error.message,
        });
      });
  };


  function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const clearElement = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    clearUploadedFile();
  };

  const clearUploadedFile = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/alzheimer-request/clear-alzheimer`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apitoken}`,
        },
      });
      if (response.ok) {
        console.log('MRI image deleted successfully');

        Swal.fire({
          icon: 'success',
          title: 'Image Cleared',
          text: 'MRI Image Deleted Successfully',
        });

        setVolumes([]);
        setEnahnceVolumes([]);
        setMaskEnhanceVolumes([]);
        setLeftHippocampus('');
        setRightHippocampus('');
      } else {
        console.error('Failed to delete MRI image:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting MRI image:', error);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      <div className="d-flex justify-content-around"><span>{file.path}</span> <span className="ms-auto">{(file.size / (1024 * 1024)).toFixed(2)} mb</span></div>
    </li>
  ));

  const downloadSegementAlzheimerImage = () => {
    const url = `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/enhanceAlzheimer.nii.gz?token=${apitoken}`;
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'enhance.nii.gz');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading the enhanced image:', error));
  };

  const downloadMaskAlzheimerImage = () => {
    const url = `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/maskEnhanceAlzheimer.nii.gz?token=${apitoken}`;
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'enhance.nii.gz');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading the enhanced image:', error));
  };

  return (
    <div>
      <div className="home-container-alzheimer-bg"></div>
      <UserHeader />
      <div className="home-container" style={{ color: '#fff' }}>
        <div className="d-flex">
          <div className="tools w-23 p-4" id="enhancingTools">
            <div className='d-flex flex-column'>

              <div id="normalSlice">

                <h4 className='mb-3'>Alzheimer's Data</h4>
                <table className="w-100">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Left Hippo. Range (cm3)</td>
                      <td>Right Hippo. Range (cm3)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Normal Brain</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Male Age <br /> (60-90)</td>
                      <td>2.509-4.404</td>
                      <td>2.532-4.404</td>
                    </tr>
                    <tr>
                      <td>Female Age <br /> (60-90)</td>
                      <td>2.509-4.131</td>
                      <td>2.532-4.131</td>
                    </tr>
                    <tr>
                      <td>AD Brain</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Age Group Avg. (60-90)</td>
                      <td>1.370-2.056 (Avg. Range)</td>
                      <td>1.310-2.247 (Avg. Range)</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mb-4">
                  <p>Select Color map image:</p>
                  <select className="form-select" name="" id="">
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-5">
                  <p>Select Color map mask:</p>
                  <select className="form-select" name="" id="">
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-d">
                  <span><i className="fs-2 fa fa-info-circle"></i></span>
                  <p>This app uses a pre-trained model to perform MRI image segmentation. The segmentation results are provided for research and educational purposes. Do not use the results for critical decisiion-making.</p>
                </div>

              </div>

            </div>
          </div>
          <div className="w-77 home-content ms-auto">

            <div className="container">
              <div className="p-md-4">

                <div className="w-90 mx-auto">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center" style={{ color: '#fff' }}>Welcome To <br /> Alzheimer's Disease Detection App</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">

                      <div className="d-flex justify-content-center align-items-center mt-3">
                        <div className="w-md-50">
                          <input className="form-control dropFile form-control-lg" type="file" id="formFile" ref={fileInputRef} disabled={volumes.length>0}/>
                          <p className="p-2">Please upload MRI image for enhancement.(format should be .nii)</p>
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="d-flex justify-content-center">
                          <div className="me-3"><button className="btn btn-1 btn-135" onClick={handleImageUpload} disabled={isUpload}>Upload <span><i className="fa fa-upload fs-5"></i></span> </button></div>
                          <div><button className="btn btn-1 btn-135" onClick={clearElement}>Clear</button></div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {volumes.length > 0                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                    <button className="btn btn-1 mt-3" onClick={enhanceImage} disabled={isImageSegmented}>Segment image</button>
                  </div>

                  <div className="mb-md-5">
                    <table className="w-100">
                        <thead>
                          <tr>
                            <td></td>
                            <td>Class</td>
                            <td>Volume(mm^3)</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Left Hippocampus</td>
                            <td>{leftHippocampus}</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Right Hippocampus</td>
                            <td>{rightHippocampus}</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>

                  {enhanceVolumes.length > 0 && <NiiVueWrapper volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVueWrapper volumes={[]} loadingText="No Data Available, please enhance file" />}
                  <div className="my-4">
                    <button className="btn btn-1 mt-3 btn-135" onClick={downloadSegementAlzheimerImage}>Download Alzheimer Image</button> <br />
                  </div>
                  {maskEnhanceVolumes.length > 0 && <NiiVueWrapper volumes={maskEnhanceVolumes} />}
                  {maskEnhanceVolumes.length <= 0 && <NiiVueWrapper volumes={[]} loadingText="No Data Available, please enhance file" />}

                  <div className="">
                    <button className="btn btn-1 btn-135 mt-4" onClick={downloadMaskAlzheimerImage}>Download Masked Image</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Alzheimer;
