import React, { useState, useEffect, useRef } from "react";
import { Box, Slider } from "@mui/material";
import Typography from "@mui/material/Typography"
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  // color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1976d2',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

// style for slider & label
const labelStyle = {
  position: "absolute",
  right: "30.7%",
  marginTop: "20px",
  color: "#212529",
};

const sliderStyle = {
  position: "absolute",
  right: "18.5%",
  marginTop: "15px",
};

export function NiivuePanel(props) {
  const canvas = useRef(null);
  const [height, setHeight] = useState(480);
  const hasLoaded = useRef(false);

  useEffect(() => {
    const resizeHandler = () => {
      const rect = canvas.current.parentNode.getBoundingClientRect();
      setHeight(rect.height);
      canvas.current.width = rect.width;
      canvas.current.height = rect.height;
      props.nv.drawScene();
    };

    const loadPanel = async () => {
      const rect = canvas.current.parentNode.getBoundingClientRect();
      setHeight(rect.height);
      canvas.current.width = rect.width;
      canvas.current.height = rect.height;
      props.nv.attachToCanvas(canvas.current);

      if (!hasLoaded.current) {
        hasLoaded.current = true; // Ensure API is only called once
        await props.nv.loadVolumes(props.volumes);
      }
      
      props.nv.drawScene();
    };

    loadPanel().then(() => window.addEventListener("resize", resizeHandler));

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [canvas,props.nv]);
 
  const [slideValue, setslideValue] = React.useState(100);
  const [boxValue, setBoxValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setBoxValue(newValue/100);
    setslideValue(newValue);
  };
  return (<>
    
    <Typography gutterBottom style={labelStyle}>Slice Opacity</Typography>
    <PrettoSlider style={sliderStyle} valueLabelDisplay="auto" aria-label="Volume" defaultValue={50} sx={{ mx: 2, width: '200px' }} value={slideValue} onChange={handleChange} />
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "400px",
        alignSelf: "flex-start",
        opacity: boxValue
      }}
    >
      <canvas id={new Date().toISOString()} ref={canvas}  />
    </Box>
    </>
  );
}
