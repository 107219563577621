import React from 'react';
import myImage from './assets/images/bg-01.jpg';
import './assets/css/LandingPage.css';
import './assets/css/Newstyling.css';
import './assets/new_css/responsive.css';
// import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/logo/Topia-Life-Sciences.png';
// import Header from './Header';
import NewHeader from './components/NewHeader';
import NewFooter from './components/NewFooter';
import HeroSection from "./components/HeroSection";
import AboutUsSection from "./components/AboutUsSection";
import Features from "./components/Features";
import Pictures from "./components/Pictures";
import Working from "./components/Working";
import ChooseUs from "./components/ChooseUs";
// import Example from "./components/Example";

const LandingPage = () => {
  return (
    <div>
      <div className="overflow-hidden">
        {/* header */}
        {/* <Header /> */}
        <NewHeader/>
        <HeroSection/>
        <AboutUsSection/>
        <ChooseUs/>
        <Features/>
        <Pictures/>
        <Working/>
        {/* <Example/> */}
        {/* <section className="section hero-section">
          <div className="container">
            <div className="hero-content d-md-flex justify-content-start align-items-center py-5 py-md-7">
              <div className="w-md-75">
                <h1 className="text-light mb-4 mb-md-5">Welcome to MRI Image Processing</h1>
                <p className="text-light mb-4">MRI stands for Magnetic Resonance Imaging. It is a medical imaging technique that uses powerful magnetic fields and radio waves to produce detailed images of the inside of the body.</p>
                <p className="text-light mb-3">MRI is used to diagnose a wide range of medical conditions and to assess the anatomy and function of various tissues and organs.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about-section" id="aboutSection">
          <div className="py-7">
            <div className="container">
              <h1 className="text-center mb-4">About Us</h1>
              <div className="py-md-5">
                <div className="row">
                  <div className="col-md-6 px-4">
                    <h2 className="mb-4">MRI Image Processing</h2>
                    <p className="mb-3">
                      MRI images can contain various types of noise, such as Gaussian noise, due to imperfections in the imaging process. The system can use filters (e.g., median filter, Gaussian filter) to reduce noise and improve image quality.
                    </p>
                    <p className="mb-3">
                      The system can adjust the contrast of the MRI images to highlight differences in tissue densities, making it easier for radiologists to distinguish between different types of tissues and structures.
                    </p>
                    <p className="mb-3">
                      This technique increases the sharpness of the image by subtracting a blurred version of the image from the original image.
                    </p>
                  </div>
                  <div className="col-md-6 px-4">
                    <figure>
                      <img className="img-fluid rounded-4" src={myImage} alt="about img" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </div>
      {/* <Footer /> */}
      {/* <div className="">
        <footer style={{ position: 'relative', bottom: '0px' }}>
          <div>
            <p className="text-end mb-0 py-2 me-3">MRI Image Processing 2024 © <a href="https://topialifesciences.com" target="_blank" rel="noopener noreferrer"><img id="footer-logo" src={logo} alt="" /></a></p>
          </div>
        </footer>
      </div> */}
      <NewFooter/>
    </div>
  );
};

export default LandingPage;
