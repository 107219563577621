import React, { useEffect, useState } from 'react';
// import logo from './assets/images/logo/Topia-Life-Sciences.png';
import logo from './assets/images/logo/TopiaLifeScience-white.png';
import './assets/css/Footer.css';
import zIndex from '@mui/material/styles/zIndex';

const Footer = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Set a timeout to hide the loader after 2 seconds (2000 milliseconds)
        const timeout = setTimeout(() => {
            setVisible(false);
        }, 2000);

        // Cleanup the timeout when the component unmounts
        return () => clearTimeout(timeout);
    }, []);

    const footerStyle = {
        // backgroundColor: "white",
        // color: "black",
        position: "relative",
        zIndex: "99",
    }

    return (
        <div className="" style={footerStyle}>
            <footer style={{ position: 'relative', bottom: '0px', color: 'white' }}>
                <div>
                    <p className="text-end mb-0 py-2 me-3">MRI Image Processing 2024 © <a href="https://topialifesciences.com" target="_blank" rel="noopener noreferrer"><img id="footer-logo" src={logo} alt="" /></a></p>
                </div>
            </footer>
            {/* <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
                <div className="section--white">
                    <div className="container-loader">
                        <div className="slice"></div>
                        <div className="slice"></div>
                        <div className="slice"></div>
                        <div className="slice"></div>
                        <div className="slice"></div>
                        <div className="slice"></div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Footer;
