import React, { useState } from 'react';
import '../assets/css/Newstyling.css';

const Working = () => {
  const [activeKey, setActiveKey] = useState(null);

  const toggleCollapse = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <section className="bg_section pt-100">
      {/* Centered Heading Outside the Main Div */}
      <div className="row">
          <div className="col-lg-12">
            <div className="section-tittle text-center mb-100">
              {/* <span>Features</span> */}
              <h2>How Alzevita Works</h2>
            </div>
          </div>
        </div>

      <div className="all-starups-area testimonial-area fix">
        {/* Left Contents */}
        <div className="starups">
          <div className="container mt-4">
            <div id="accordion">
              {/* Data Input Section */}
              <div className="card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(1)}
                >
                  <span>Data Input​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 1 && (
                  <div className="card-body">
                    Upload T1-weighted MRI scans to the Alzevita cloud platform
                  </div>
                )}
              </div>

              {/* Preprocessing Section */}
              <div className="card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(2)}
                >
                  <span>Preprocessing​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 2 && (
                  <div className="card-body">
                    <ul>
                      <li>Denoising: Reduces noise for clearer images.​</li>
                      <li>Registration: Aligns MRI images for consistency.​</li>
                      <li>Intensity Normalization: Standardizes contrast across different scans</li>
                    </ul>
                  </div>
                )}
              </div>

              {/* 3D Segmentation Section */}
              <div className="card custom_card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(3)}
                >
                  <span>3D Segmentation​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 3 && (
                  <div className="card-body">
                    Accurately segments the hippocampal region from MRI scans.
                  </div>
                )}
              </div>

              {/* Volume Calculation Section */}
              <div className="card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(4)}
                >
                  <span>Volume Calculation​​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 4 && (
                  <div className="card-body">
                    Automatically calculates hippocampal volume based on the segmented regions.
                  </div>
                )}
              </div>

              {/* Real-Time Results Section */}
              <div className="card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(5)}
                >
                  <span>Real-Time Results​ ​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 5 && (
                  <div className="card-body">
                    Displays segmentation and volume metrics instantly through an intuitive interface.​ 
                  </div>
                )}
              </div>

              {/* Clinical Integration Section */}
              <div className="card rounded-4">
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  onClick={() => toggleCollapse(6)}
                >
                  <span>Clinical Integration​</span>
                  <span>&#x25BC;</span>
                </div>
                {activeKey === 6 && (
                  <div className="card-body">
                    Designed to fit seamlessly into clinical workflows, supporting diagnosis and reporting.​
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Contents (empty, add content here if needed) */}
        <div className="starups-img"></div>
      </div>
    </section>
  );
};

export default Working;
