import React from 'react';
import { Breadcrumb, Button, Container, Row, Col } from 'react-bootstrap'; 
import AlzheimerDataTable from './AlzheimerDataTable'; 
import './assets/css/Home.css';
import { useNavigate } from 'react-router-dom';

const AlzheimerUserHistory = () => {
    const navigate = useNavigate();
    const uploadFile = () => {
        navigate('/alzheimermain');
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Topia</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <div className="d-flex align-items-center justify-content-between border border-dashed border-gray-300 rounded p-2 w-220px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <div className="me-4">
                                        <a href="#" className="text-dark text-hover-primary fs-6 fw-bold">John Dev</a>
                                        <a href="#" className="text-gray-400 text-hover-primary fs-7 fw-bold d-block">john.dev@mail.com</a>
                                    </div>
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-500 pt-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="user-history-container">
                <div className="container">
                    <div className="wrapper">
                        <div className="content-wrapper py-3">
                            <div className="pb-3">
                                <div className="d-flex align-items-center">
                                    <ol className="breadcrumb mb-0">
                                        <li className=""><a className="black-text" href="#"><i className="fa fa-home"></i></a><i className="fa fa-angle-right mx-2" aria-hidden="true"></i></li>
                                        <li className=""><a className="black-text" href="#">Dashboard</a></li>
                                    </ol>
                                    <div className="ms-auto">
                                        <button className="btn btn-sm btn-primary" onClick={uploadFile}>Upload new file</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="d-flex align-items-center card-title">
                                        <span className="fs-5 fw-bold">Alzheimer User History</span>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <AlzheimerDataTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlzheimerUserHistory;
