import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './assets/css/Home.css';
import './assets/css/Alzheimer.css';
import './assets/dropzone/plugins.bundle.css';
import Footer from './Footer';
import NiiVue from './Niivue';
import NiiVueWrapper from './NiiVueWrapper';
import API_BASE_URL from './config';
import UserHeader from './UserHeader';
import NiivueMask from './NiivueMask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlzheimerView = () => {

  const apitoken = localStorage.getItem('Token');
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnahnceVolumes] = useState([]);
  const [maskEnhanceVolumes, setMaskEnhanceVolumes] = useState([]);
  const [leftHippocampus, setLeftHippocampus] = useState('');
  const [rightHippocampus, setRightHippocampus] = useState('');
  const [isImageSegmented, setIsImageSegmented] = useState(false);
  const [visible, setVisible] = useState(true);
  const [segmentDownload, setSegmentDownloading] = useState(false);
  const [maskDownload, setMaskDownloading] = useState(false);
  const id = location.pathname.split('/').pop();
  const hasFetchedView = useRef(false);
  const hasFetchedEnhance = useRef(false);
  const hasFetchedMask = useRef(false);

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      try {
        const response = await fetch(`${API_BASE_URL}/alzheimer-request/hippocampus/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${apitoken}`,
          },
          signal: abortController.signal,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch hippocampus data');
        }

        const data = await response.json();
        if (data.status === 0) {
          setLeftHippocampus(data.data.leftHippocampus);
          setRightHippocampus(data.data.rightHippocampus);
        } else {
          console.error('Failed to fetch hippocampus data:', data.msg);
        }
      } catch (error) {
        console.error('Error fetching hippocampus data:', error);
      }
    };

    fetchData();
  }, [apitoken]);

  useEffect(() => {

    if (!id) {
        console.error('No ID provided.');
        return;
      }

    if (!hasFetchedView.current) {
        hasFetchedView.current = true; // Prevent future calls
        fetch(`${API_BASE_URL}/alzheimer-request/view-alzheimer/${id}/alzheimer.nii.gz`,
        {
        }).then((response) => {
          if (response.status == 200) {
           let volumes = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-alzheimer/${id}/alzheimer.nii.gz`,
            },
          ];
          setVolumes(volumes);
        }
      })
    }
  }, [id, apitoken])

  useEffect(() => {

    if (!hasFetchedEnhance.current) {
      hasFetchedEnhance.current = true; // Prevent future calls
    
      fetch(`${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/${id}/enhanceAlzheimer.nii.gz`,
      {
      }).then((response) => {
      if (response.status == 200) {
      let enhanceVolumes = [
        {
          url: `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/${id}/enhanceAlzheimer.nii.gz`,
        },
      ];
      setEnahnceVolumes(enhanceVolumes);
      }
      })
    }
  }, [])

  useEffect(() => {
    if (!hasFetchedMask.current) {
      hasFetchedMask.current = true; // Prevent future calls
    
      fetch(`${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/${id}/maskEnhanceAlzheimer.nii.gz`,
        {
        }).then((response) => {
          if (response.status == 200) {
            let maskEnhanceVolumes = [
              {
                url: `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/${id}/maskEnhanceAlzheimer.nii.gz`,
              },
            ];
            setMaskEnhanceVolumes(maskEnhanceVolumes);
          }
        })
    }
  }, [])

  
  const downloadSegementAlzheimerImage = () => {
    if (enhanceVolumes.length > 0) {
      setSegmentDownloading(true); 
      setVisible(true); 
  
      const url = `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/${id}/enhanceAlzheimer.nii.gz`;
      
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'segment-alzheimer.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Segment Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the enhanced image:', error);
          toast.error('Error downloading the enhanced image');
        })
        .finally(() => {
          setSegmentDownloading(false); 
          setVisible(false); 
        });
    } else {
      toast.warning('No Image Loaded');
      setSegmentDownloading(false); 
      setVisible(false); 
    }
  };

  const downloadMaskAlzheimerImage = () => {
    if (maskEnhanceVolumes.length > 0) {
      setMaskDownloading(true); 
      setVisible(true); 
  
      const url = `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/${id}/maskEnhanceAlzheimer.nii.gz`;
  
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mask-alzheimer.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Mask Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the mask image:', error);
          toast.error('Error downloading the mask image');
        })
        .finally(() => {
          setMaskDownloading(false); 
          setVisible(false); 
        });
    } else {
      toast.warning('No Image Loaded');
      setMaskDownloading(false); 
      setVisible(false); 
    }
  };
  
  return (
    <div>
      <div className="home-container-alzheimer-bg"></div>
      <UserHeader />
      <div className="home-container blur-bg-container" style={{ color: '#fff' }}>
        <div className="d-flex">
          <div className="tools w-23 p-4" id="enhancingTools">
            <div className='d-flex flex-column'>

              <div id="normalSlice">

                <h4 className='mb-3'>Alzheimer's Data</h4>
                <table className="w-100">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Left Hippo. Range (cm3)</td>
                      <td>Right Hippo. Range (cm3)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Normal Brain</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Male Age <br /> (60-90)</td>
                      <td>2.509-4.404</td>
                      <td>2.532-4.404</td>
                    </tr>
                    <tr>
                      <td>Female Age <br /> (60-90)</td>
                      <td>2.509-4.131</td>
                      <td>2.532-4.131</td>
                    </tr>
                    <tr>
                      <td>AD Brain</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Age Group Avg. (60-90)</td>
                      <td>1.370-2.056 (Avg. Range)</td>
                      <td>1.310-2.247 (Avg. Range)</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mb-4">
                  <p>Select Color map image:</p>
                  <select className="form-select" name="" id="" disabled>
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-5">
                  <p>Select Color map mask:</p>
                  <select className="form-select" name="" id="" disabled>
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-d">
                  <span><i className="fs-2 fa fa-info-circle"></i></span>
                  <p>The segmentation results are provided for research and educational purposes. Do not use the results for critical decisiion-making.</p>
                </div>

              </div>

            </div>
          </div>
          <div className="w-77 home-content ms-auto">

            <div className="container">
              <div className="p-md-4">

                <div className="w-90 mx-auto">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center" style={{ color: '#fff' }}>Alzevita<br /> Alzheimer Detection App</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">

                      <div className="d-flex justify-content-center align-items-center mt-3">
                      </div>

                      <div className="mb-4">
                      </div>

                    </div>
                  </div>
                  {volumes.length > 0                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                  </div>

                  <div className="mb-md-5">
                    <table className="w-100">
                        <thead>
                          <tr>
                            <td>Sr no.</td>
                            <td>Class</td>
                            <td>Volume(mm^3)</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Left Hippocampus</td>
                            <td>{leftHippocampus}</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Right Hippocampus</td>
                            <td>{rightHippocampus}</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>

                  {enhanceVolumes.length > 0 && <NiiVue volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please enhance file" />}
                  <div className="my-4">
                    <button className="btn btn-1 mt-3 btn-135" onClick={downloadSegementAlzheimerImage}>Download Alzheimer Image {segmentDownload}</button> <br />
                  </div>
                  {maskEnhanceVolumes.length > 0 && <NiivueMask volumes={maskEnhanceVolumes} />}
                  {maskEnhanceVolumes.length <= 0 && <NiivueMask volumes={[]} loadingText="No Data Available, please enhance file" />}

                  <div className="">
                    <button className="btn btn-1 btn-135 mt-4" onClick={downloadMaskAlzheimerImage}>Download Masked Image {maskDownload}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
}

export default AlzheimerView;
