import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import './assets/css/style.css';
import logo from './assets/images/logo/Topia-Life-Sciences.png';
import EnhanceDataTable from './EnhanceDataTable';
import AlzheimerDataTable from './AlzheimerDataTable';
import API_BASE_URL from './config';
import UserProfile from './UserProfile';

const UserHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const apitoken = localStorage.getItem('Token');
    const [modalType, setModalType] = useState(null);
    const [userDetails, setUserDetails] = useState({ firstName: '', email: '' });

    useEffect(() => {
        if (apitoken) {
            fetchUserDetails(apitoken).then(data => {
                if (data) {
                    setUserDetails({ firstName: data.firstName, email: data.email });
                }
            });
        }
    }, [apitoken]);

    const fetchUserDetails = async (token) => {
        // const abortController = new AbortController();
        try {
            const response = await fetch(`${API_BASE_URL}/mri-users/user-detail-id`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                // signal: abortController.signal,
            });
            const result = await response.json();
            if (result.status === 0) {
                return result.data;
            } else {
                console.error("Failed to fetch user details:", result.msg);
                return null;
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
            return null;
        }
    };

   
    const handleClose = () => setModalType(null);
    const handleShow = (type) => setModalType(type);

    const handleLogout = () => {
        localStorage.removeItem('Token');
        navigate('/login');
    };

    const handleHome = () => {
        navigate('/enhance');
    };

    const handleAlzheimer = () => {
        navigate('/alzheimermain');
    };

    const handlePassword = () => {
        navigate('/changepassword');
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="logo" className="logo-img" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto align-items-center">
                            {/* <li className="nav-item me-2">
                                <a className={`nav-link ${location.pathname === '/enhance' ? 'active' : ''}`} aria-current="page" onClick={handleHome} style={{cursor: 'pointer'}}>Enhance</a>
                            </li> */}
                            <li className="nav-item d-none">
                                <a className="nav-link" href="#">Enhance</a>
                            </li>
                            <li className="nav-item me-3">
                                <a className={`nav-link ${location.pathname === '/alzheimermain' ? 'active' : ''}`} aria-current="page" onClick={handleAlzheimer} style={{cursor: 'pointer'}}>Alzevita</a>
                            </li>
                        </ul>

                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item dropdown-container">
                                <a >
                                    <div className="d-flex align-items-center justify-content-between border border-dashed border-gray-300 rounded p-2 w-220px">
                                        <div className="me-3 user-details-container">
                                            <span className="text-dark text-hover-primary fs-6 fw-bold">{userDetails.firstName}</span>
                                            <span className="fs-6 fw-bold d-block text-primary user-details-email">{userDetails.email}</span>
                                        </div>
                                        <span className="svg-icon svg-icon-2 svg-icon-gray-500 pt-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </div>
                                </a>

                                <div className="dropdown-content menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color py-2 fs-6 w-275px">
                                    <div className="menu-item">
                                        <a className="menu-link" onClick={() => handleShow('userProfile')}>
                                            <i className="fa fa-user me-2"></i><span className="menu-text">Profile</span>
                                        </a>
                                        {/* <a className="menu-link" onClick={() => handleShow('enhance')}>
                                            <i className="fa fa-clock-rotate-left me-2"></i><span className="menu-text">Enhance History</span>
                                        </a> */}
                                        <a className="menu-link" onClick={() => handleShow('alzheimer')}>
                                            <i className="fa fa-clock-rotate-left me-2"></i><span className="menu-text">Alzheimer History</span>
                                        </a>
                                        <a className="menu-link" onClick={handlePassword}>
                                            <i className="fa fa-lock me-2"></i><span className="menu-text">Change Password</span>
                                        </a>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="menu-item">
                                        <a className="menu-link sign-out-link" onClick={handleLogout}> <i className="fa fa-sign-out-alt me-2"></i>  Sign Out</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Enhance history modal */}
            {/* <Modal show={modalType === 'enhance'} onHide={handleClose} dialogClassName="modal-xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enhance History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EnhanceDataTable />
                </Modal.Body>
            </Modal> */}

            {/* Alzheimer history modal */}
            <Modal show={modalType === 'alzheimer'} onHide={handleClose} dialogClassName="modal-xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Alzheimer History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlzheimerDataTable />
                </Modal.Body>
            </Modal>

            {/* user profile modal */}
            <Modal show={modalType === 'userProfile'} onHide={handleClose} dialogClassName="modal-lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserProfile/>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default UserHeader;
