import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './assets/css/Home.css';
import './assets/css/Alzheimer.css';
import './assets/dropzone/plugins.bundle.css';
import Footer from './Footer';
import NiiVue from './Niivue';
import NiiVueWrapper from './NiiVueWrapper';
import API_BASE_URL from './config';
import UserHeader from './UserHeader';
import NiivueMask from './NiivueMask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlzheimerMain = () => {

  const fileInputRef = useRef(null);
  const apitoken = localStorage.getItem('Token');
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnahnceVolumes] = useState([]);
  const [maskEnhanceVolumes, setMaskEnhanceVolumes] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [leftHippocampus, setLeftHippocampus] = useState('');
  const [rightHippocampus, setRightHippocampus] = useState('');
  const [isImageSegmented, setIsImageSegmented] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isDownloadSegmentImage, setIsDownloadSegmentImage] = useState(false);
  const [isDownloadSegmentMaskImage, setIsDownloadSegmentMaskImage] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [id, setId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [download, setDownloading] = useState(false);

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  const handleDemo = () => {
    try {
      setDemoLoading(true);
      setVisible(true);

      setTimeout(() => {
        const demoAlzheimerImage = [
          {
            url: '/demoimage/IXI079-HH-1388-T1.nii.gz',
          },
        ];
        setVolumes(demoAlzheimerImage);

        const demoAlzheimerEnhanceImage = [
          {
            url: '/demoimage/alzheimer-enhance.nii.gz',
          },
        ];
        setEnahnceVolumes(demoAlzheimerEnhanceImage);

        const demoMaskAlzheimerImage = [
          {
            url: '/demoimage/mask-enhance.nii.gz',
          },
        ];
        setMaskEnhanceVolumes(demoMaskAlzheimerImage);
        setLeftHippocampus(4007);
        setRightHippocampus(4052);
        setIsDownloadSegmentImage(true);
        setIsDownloadSegmentMaskImage(true);
        setIsDemo(true);
        setVisible(false);
        setDemoLoading(false);
      }, 100);
    } catch (error) {
      console.error('Error in demo:', error);
      toast.error('Failed to load demo. Please try again later.');
      setVisible(false);
      setDemoLoading(false);
    }
  };

  const handleImageUpload = async () => {

    if (volumes.length > 0) {
      toast.warning('To proceed with a new image upload, please clear the current image');
      return;
    }

    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      setIsUpload(true);
      setIsDemo(true);
      setUploading(true);
      setVisible(true);
      const uploaded = fileInputRef.current.files[0];
      const validFileExtensions = ['nii', 'nii.gz', 'dcm'];
      const fileName = uploaded.name.toLowerCase();

      const isValidExtension = validFileExtensions.some(extension =>
        fileName.endsWith(`.${extension}`)
      );

      console.log('Valid file extensions:', validFileExtensions);
      console.log('Uploaded file name:', fileName);

      if (!isValidExtension) {
        toast.error('Please upload a valid .nii, .nii.gz or .dcm file');
        setUploading(false);
        return;
      }

      setUploadedFile(uploaded);

      // Check if the file is a .dcm file
      const isDcmFile = fileName.endsWith('.dcm');

      const formData = new FormData();
      // If the file is NOT a .dcm file, include it in the FormData
      if (!isDcmFile) {
        console.log('Non-DICOM file. Including file in upload...');
        formData.append('file', uploaded);
      } else {
        console.log('DICOM file detected. Excluding file from upload...');
        // Example: Assuming `dcmFiles` is an array of selected DICOM files
        const dcmFiles = fileInputRef.current.files; // All selected files

        // Loop through the files and append them to FormData
        for (let i = 0; i < dcmFiles.length; i++) {
          formData.append('files', dcmFiles[i]); // 'files' is the field name for multiple files
        }

        console.log(dcmFiles.length);
      }

      try {
        //const formData = new FormData();
        //formData.append('file', uploaded);

        const response = await fetch(`${API_BASE_URL}/alzheimer-request/upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apitoken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        if (data.status === 200) {
          const newId = data.data.id;
          setId(newId);

          if(isDcmFile){

            const responseForSave = await fetch(`${API_BASE_URL}/alzheimer-request/save-dicom-image/${newId}`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${apitoken}`,
              },
              body: formData,
            });

            // Check if response status is not 200
            if (responseForSave.status !== 200) {
              const errorMessage = `Error saving DICOM image.`;
              toast.error(errorMessage);  // Display error message using toast
              return;  // Stop the process
            }

            const data = await responseForSave.json();

            if(data.status === 200){
              const responseForConvert = await fetch(`${API_BASE_URL}/alzheimer-request/niiConversion/${newId}`, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${apitoken}`,
                },
                
              });

              // Check if response status is not 200
              if (responseForConvert.status !== 200) {
                const errorMessage = `Error converting DICOM to NII.`;
                toast.error(errorMessage);  // Display error message using toast
                return;  // Stop the process
              }
              const data = await responseForConvert.json();
            } else {
              // Handle other cases where data.status is not 200
              toast.error('Failed to save DICOM image.');
            }
          }
          
          const NVRVolumeImage = [
            {
              url: `${API_BASE_URL}/alzheimer-request/view-alzheimer/${newId}/alzheimer.nii.gz`,
            },
          ];
          toast.success('File Uploaded');
          setVolumes(NVRVolumeImage);
          setUploading(false);
          document.getElementById('formFile').disabled = true;

          if (data.data && data.data.image) {
            const blob = b64toBlob(data.data.image, 'image/jpeg');
            const imageUrlFromBlob = URL.createObjectURL(blob);
            setImageURL(imageUrlFromBlob);
          } else {
            console.error('Image data is not available');
          }
        } else {
          console.error('Failed to fetch image URL');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    finally {
      setUploading(false);
      setVisible(false);
    }
    } else {
      toast.warn('Please choose a file to upload');
    }
  };

  useEffect(() => {
    setIsImageSegmented(enhanceVolumes.length > 0 && maskEnhanceVolumes.length > 0);
    setIsUpload(volumes.length > 0 && enhanceVolumes.length > 0 && maskEnhanceVolumes.length > 0)
  }, [volumes.length, enhanceVolumes.length, maskEnhanceVolumes.length]);

  const enhanceImage = () => {
    if (volumes.length > 0) {
      setIsUpload(true);
      setIsImageSegmented(true);
      setEnhancing(true);
      setVisible(true);
      fetch(`${API_BASE_URL}/alzheimer-request/enhance-alzheimer/${id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${apitoken}` },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to enhance image');
          }
          return response.json();
        })
        .then(responseData => {
          const parsedData = JSON.parse(responseData.data);

          if (parsedData.success === "Image Segment Successfully") {

            setLeftHippocampus(parsedData.left_hippocampus);
            setRightHippocampus(parsedData.right_hippocampus);
            const NVRVolumeEnhanceImage = [
              { url: `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/${id}/enhanceAlzheimer.nii.gz` },
            ];
            setEnahnceVolumes(NVRVolumeEnhanceImage);

            const maskEnhanceVolumes = [
              { url: `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/${id}/maskEnhanceAlzheimer.nii.gz` },
            ];
            setMaskEnhanceVolumes(maskEnhanceVolumes);
            toast.success('Image Segmented Successfully');
            setEnhancing(false);
            setVisible(false);
          } else {
            throw new Error('Image segmentation failed');
          }
        })
        .catch(error => {
          console.error('Error enhancing image:', error);
          toast.error('Server Corrupted');
        })
        .finally(() => {
          setEnhancing(false);
          setVisible(false);
        });
    } else {
      toast.error('Please Upload Image For Segmentation');
    }
  };


  function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const clearElement = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    clearUploadedFile();
  };

  const clearUploadedFile = async () => {
    toast.success('Image Cleared');
    setVolumes([]);
    setEnahnceVolumes([]);
    setMaskEnhanceVolumes([]);
    setLeftHippocampus('');
    setRightHippocampus('');
    setIsDownloadSegmentImage(false);
    setIsDownloadSegmentMaskImage(false);
    setIsUpload(false);
    setIsDemo(false);
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      <div className="d-flex justify-content-around"><span>{file.path}</span> <span className="ms-auto">{(file.size / (1024 * 1024)).toFixed(2)} mb</span></div>
    </li>
  ));

  const downloadSegementAlzheimerImage = () => {
    if (enhanceVolumes.length > 0) {
      setDownloading(true); 
      setVisible(true); 
  
      const url = `${API_BASE_URL}/alzheimer-request/view-enhance-alzheimer/${id}/enhanceAlzheimer.nii.gz`;
      
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'segment-alzheimer.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Segment Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the enhanced image:', error);
          toast.error('Error downloading the enhanced image');
        })
        .finally(() => {
          setDownloading(false); 
          setVisible(false); 
        });
    } else {
      toast.warning('No Image Loaded');
      setDownloading(false); 
      setVisible(false); 
    }
  };

  const downloadMaskAlzheimerImage = () => {
    if (maskEnhanceVolumes.length > 0) {
      setDownloading(true); 
      setVisible(true); 
  
      const url = `${API_BASE_URL}/alzheimer-request/view-mask-alzheimer/${id}/maskEnhanceAlzheimer.nii.gz`;
  
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mask-alzheimer.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Mask Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the mask image:', error);
          toast.error('Error downloading the mask image');
        })
        .finally(() => {
          setDownloading(false); 
          setVisible(false); 
        });
    } else {
      toast.warning('No Image Loaded');
      setDownloading(false); 
      setVisible(false); 
    }
  };

  return (
    <div>
      <div className="home-container-alzheimer-bg"></div>
      <UserHeader />
      <div className="home-container blur-bg-container" style={{ color: '#fff' }}>
        <div className="d-flex">
          <div className="tools w-23 p-4" id="enhancingTools">
            <div className='d-flex flex-column'>

              <div id="normalSlice">

                <h4 className='mb-3'>Alzheimer's Data</h4>
                <table className="w-100">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Left Hippo. Range (cm3)</td>
                      <td>Right Hippo. Range (cm3)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Normal Brain</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Male Age <br /> (60-90)</td>
                      <td>2.509-4.404</td>
                      <td>2.532-4.404</td>
                    </tr>
                    <tr>
                      <td>Female Age <br /> (60-90)</td>
                      <td>2.509-4.131</td>
                      <td>2.532-4.131</td>
                    </tr>
                    <tr>
                      <td>AD Brain</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Age Group Avg. (60-90)</td>
                      <td>1.370-2.056 (Avg. Range)</td>
                      <td>1.310-2.247 (Avg. Range)</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mb-4">
                  <p>Select Color map image:</p>
                  <select className="form-select" name="" id="" disabled>
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-5">
                  <p>Select Color map mask:</p>
                  <select className="form-select" name="" id="" disabled>
                    <option value="">grey</option>
                    <option value="">black</option>
                    <option value="">white</option>
                  </select>
                </div>

                <div className="mb-d">
                  <span><i className="fs-2 fa fa-info-circle"></i></span>
                  <p>The segmentation results are provided for research and educational purposes. Do not use the results for critical decisiion-making.</p>
                </div>

              </div>

            </div>
          </div>
          <div className="w-77 home-content ms-auto">

            <div className="container">
              <div className="py-md-4">

                <div className="w-90 mx-auto">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center" style={{ color: '#fff' }}>Welcome To <br /> Alzheimer Detection App - Alzevita</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">

                      <div className="d-flex justify-content-center align-items-center mt-3">
                        <div className="">
                          <input className="form-control dropFile form-control-lg w-md-90 mx-auto" type="file" id="formFile" ref={fileInputRef} disabled={volumes.length > 0} accept=".nii,.nii.gz,.dcm" multiple />
                          <p className="p-2">Please upload MRI image for enhancement.(format should be .nii)</p>
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="d-flex justify-content-center">
                          <div className="me-3"><button className={`btn btn-1 btn-135 ${isUpload ? 'disabled-button' : ''}`}  onClick={handleImageUpload} disabled={isUpload}>Upload {uploading}<span><i className="fa fa-upload fs-5"></i></span> </button></div>
                          <div><button className="btn btn-1 btn-135" onClick={clearElement}>Clear</button></div>

                          <div className="ms-3"><button className="btn btn-1 btn-135" onClick={handleDemo} disabled={isDemo}>Demo {demoLoading}</button></div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {volumes.length > 0 && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                    <button className="btn btn-1 mt-3" onClick={enhanceImage} disabled={isImageSegmented}>Segment image {enhancing}</button>
                  </div>

                  <div className="mb-md-5">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <td className="w-10">Sr no.</td>
                          <td>Class</td>
                          <td>Volume(mm^3)</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Left Hippocampus</td>
                          <td>{leftHippocampus}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Right Hippocampus</td>
                          <td>{rightHippocampus}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {enhanceVolumes.length > 0 && <NiiVue volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please enhance file" />}
                  <div className="my-4">
                    <button className="btn btn-1 mt-3 btn-135" onClick={downloadSegementAlzheimerImage} disabled={isDownloadSegmentImage}>Download Alzheimer Image {download}</button> <br />
                  </div>
                  {maskEnhanceVolumes.length > 0 && <NiivueMask volumes={maskEnhanceVolumes} />}
                  {maskEnhanceVolumes.length <= 0 && <NiivueMask volumes={[]} loadingText="No Data Available, please enhance file" />}

                  <div className="">
                    <button className="btn btn-1 btn-135 mt-4" onClick={downloadMaskAlzheimerImage} disabled={isDownloadSegmentMaskImage}>Download Masked Image {download}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
}

export default AlzheimerMain;
