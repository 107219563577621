import React from 'react';
import NiiVue from './Niivue';

function NiiVueWrapper({ volumes }) {
  return (
    <div>
      <NiiVue volumes={volumes} />
    </div>
  );
}

export default NiiVueWrapper;
