import React from "react";
import '../assets/css/Newstyling.css';
// import video from '../assets/images/new_brain-1.mp4';
import video from '../assets/images/new_brain.mov';
// import  '../assets/images/new_brain-1.mp4';

const HeroSection = () => {
  return (
    <section className="slider-area position-relative ">
      <video className="background-video" autoPlay loop muted playsInline >
        <source src={video} type="video/mp4" />
        {/* Add more source formats for better browser support */}
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      <div className="slider-active">
        {/* Single Slider */}
        <div className=" slider-height d-flex align-items-center">
          
           <div className="container">
            <div className="col-12 col-md-6">
              <div className="hero__caption ">
                <span>Committed to success</span>
                <h1 className="cd-headline letters scale ">
                  "Unlocking Brain Health<br></br> Insights Through AI."
                  {/* We care about your{""} */}

                </h1>
                {/* <p data-animation="fadeInLeft" data-delay="0.1s">s
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi uquip ex ea commodo consequat is aute irure.
                  </p> */}
                {/* <a
                    href="#"
                    className="btn hero-btn"
                    data-animation="fadeInLeft"
                    data-delay="0.5s"
                  >
                    Appointment <i className="ti-arrow-right"></i>
                  </a> */}
              </div>
            </div>
            </div>

         
        </div>
        {/* Single Slider */}
        {/* <div className="single-slider slider-height d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9 col-md-8 col-sm-9">
                <div className="hero__caption">
                  <span>Committed to success</span>
                  <h1 className="cd-headline letters scale">
                    We care about your{" "}
                    <strong className="cd-words-wrapper">
                      <b className="is-visible">health</b>
                      <b>sushi</b>
                      <b>steak</b>
                    </strong>
                  </h1>
                  <p data-animation="fadeInLeft" data-delay="0.1s">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi uquip ex ea commodo consequat is aute irure.
                  </p>
                  <a
                    href="#"
                    className="btn hero-btn"
                    data-animation="fadeInLeft"
                    data-delay="0.5s"
                  >
                    Appointment <i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default HeroSection;
