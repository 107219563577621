import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS (if not already in your project)
import "../assets/css/Newstyling.css"; // Import your custom styles if any

const ChooseUs = () => {
    return (
        <section className="back_img2">
            <div className="py-5">
                <div className="container">
                    <div className="row back_img1">
                        <div className="col-12 py-3">
                            {/* <div className="bg-holder bg-size">
                                
                            </div> */}
                            <div className=" section-tittle text-center">
                                <h2 className="text-center">Why Choose Us</h2>
                                <span>Alzevita Provides you</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className=" blocks">
      <div className="bg-holder bg-size"></div>

      <div className="container">
        <div className="row align-items-center ">
          {/* Static row with three columns */}
          <div className="blocks_sect col-4">
          <div className="col-11 text-center">
            <h3>Accurate Early Diagnosis:</h3>
            <p>Provides precise hippocampal segmentation and volume analysis tosupport early Alzheimer’s detection.</p>
          </div>
          </div>

          <div className="blocks_sect col-4">
          <div className="col-11 text-center">
            <h3>Automated and Fast Processing:</h3>
            <p>Reduces manual effort with automated analysis, delivering real-time results for quick decision-making.</p>
          </div>
          </div>

          <div className="blocks_sect col-4">
          <div className="col-11 text-center">
            <h3>Seamless Clinical Integration: </h3>
            <p>Easily integrates into clinical workflows, enhancing efficiency and supporting better patient care.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    </section >
    );
};

export default ChooseUs;
