import React, { useState } from 'react';
import './assets/css/Signup.css'; // Assuming you have appropriate CSS for styling
import { Link, useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewHeader from './components/NewHeader';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactNumberError, setContactNumberError] = useState('');
  const navigate = useNavigate();

  const validateFirstName = () => {
    const nameRegex = /^[a-zA-Z]{1,15}$/;
    if (!firstName.trim()) {
      setFirstNameError('First Name is required');
      return false;
    } else if (!nameRegex.test(firstName)) {
      setFirstNameError('First Name must be up to 15 characters long and contain no special characters or numbers');
      return false;
    }
    setFirstNameError('');
    return true;
  };

  const validateLastName = () => {
    const nameRegex = /^[a-zA-Z]{1,15}$/;
    if (!lastName.trim()) {
      setLastNameError('Last Name is required');
      return false;
    } else if (!nameRegex.test(lastName)) {
      setLastNameError('Last Name must be up to 15 characters long and contain no special characters or numbers');
      return false;
    }
    setLastNameError('');
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isPasswordValid = validatePassword();
    const isEmailValid = validateEmail();
    const isContactNumberValid = validateContactNumber();

    if (!(isFirstNameValid && isLastNameValid && isPasswordValid && isEmailValid && isContactNumberValid)) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/mri-users/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          profession,
          contactNumber,
        }),
      });

      if (response.ok) {
        const responseBody = await response.json();
        if (responseBody.status === 0) {
          toast.success('User Registered, An email has been sent for verification');
          navigate('/otpverify');
        } else {
          toast.error(responseBody.msg || 'User not created!');
        }
      } else {
        toast.error('Failed to register user. Please try again later');
      }
    } catch (error) {
      toast.error('An error occurred while processing your request. Please try again later.');
    }
  };

  return (
    <div>
      <NewHeader />
      <div className="login-bg">
        <div className="login-container-bg"></div>
        <div className="login-form-container">
          <div className="container">

            <div className="w-md-80 mx-auto">
              <div className="row overflow-hidden rounded-5">
                <div className="col-6 img-login px-0 h-auto bg-login-page">
                  {/* Replace with the image path */}
                  {/* <img src="/src/assets/images/new_images/features.webp" className="img-fluid" alt="Feature" /> */}
                </div>

                <div className="col-6 mx-auto p-5 " id="form-card2">
                  <div className='mx-auto custom_input2'>
                    <h1 className="text-center text-light pb-3 fs-1">Create Your Account</h1>
                    <form onSubmit={handleSignUp}>
                      <div className='row form_entry mx-auto'>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label className="form-label text-light" htmlFor="firstName">First Name</label>
                            <input type="text" className="form-control" placeholder="Enter First Name" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            {firstNameError && <div className="text-danger">{firstNameError}</div>}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label className="form-label text-light" htmlFor="lastName">Last Name</label>
                            <input type="text" className="form-control rounded-3" placeholder="Enter Last name" id="lastName" value={lastName} maxLength="15" pattern="[a-zA-Z]+" onChange={(e) => { const inputValue = e.target.value.replace(/[^a-zA-Z]/g, ''); setLastName(inputValue); }} required />
                            {lastNameError && <div className="text-danger">{lastNameError}</div>}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-4">
                            <label className="form-label text-light" htmlFor="email">Email</label>
                            <input type="email" className="form-control rounded-3" placeholder="Enter Email" id="email" value={email} maxLength="50" pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" onChange={(e) => setEmail(e.target.value)} required />
                            {emailError && <div className="text-danger">{emailError}</div>}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-4">
                            <label className="form-label text-light" htmlFor="password">Password</label>
                            <input type="password" className="form-control rounded-3" placeholder="Enter Password" id="password" value={password} minLength="8" onChange={(e) => setPassword(e.target.value)} required />
                            {passwordError && <div className="text-danger">{passwordError}</div>}
                          </div>
                        </div>
                        <div className="col-12 pb-3">
                          <div className="mb-3">
                            <label className="form-label text-light" htmlFor="profession">Profession</label>
                            <input type="text" className="form-control rounded-3" placeholder="Enter Profession" id="profession" value={profession} maxLength="20" onChange={(e) => { const inputValue = e.target.value.replace(/[^a-zA-Z]/g, ''); setProfession(inputValue); }} />
                          </div>
                        </div>
                        <div className="col-12 pb-3">
                          <div className="mb-4">
                            <label className="form-label text-light" htmlFor="contactNumber">Contact Number</label>
                            <input type="text" className="form-control rounded-3" placeholder="Enter Contact Number" id="contactNumber" value={contactNumber} maxLength="10" pattern="[0-9]*" onChange={(e) => { const sanitizedInput = e.target.value.replace(/\D/g, ''); setContactNumber(sanitizedInput); }} required />
                            {contactNumberError && <div className="text-danger">{contactNumberError}</div>}
                          </div>
                        </div>
                      </div>

                      {/* Add other fields here like last name, email, etc., similar to the above */}

                      <div className="text-center">
                        <button type="submit" className="btn btn-primary py-2 fs-5 rounded-4">Sign Up</button>
                      </div>

                      <div className="text-center mt-3">
                        <p className="text-light">
                          Already have an account? <Link to="/login" className="text-decoration-underline text-light">Login</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Signup;
