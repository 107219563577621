import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = () => {
    const [user, setUser] = useState({
        id: null,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        profession: '',
        contactNumber: '',
    });
    const [editUser, setEditUser] = useState({
        id: null,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        profession: '',
        contactNumber: '',
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true); 

    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         try {
    //             const token = localStorage.getItem('Token');
    //             const response = await axios.get(`${API_BASE_URL}/mri-users/user-detail-id`, {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 }
    //             });
    //             const userData = response.data.data;
    //             setUser(userData);
    //             setEditUser(userData); 
    //             setIsLoading(false);
    //         } catch (error) {
    //             console.error('There was an error fetching the user data!', error);
    //         }
    //     };

    //     fetchUserData();
    // }, []);

    useEffect(() => {
        const abortController = new AbortController();

        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('Token');
                const response = await axios.get(`${API_BASE_URL}/mri-users/user-detail-id`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    signal: abortController.signal,
                });
                const userData = response.data.data;
                setUser(userData);
                setEditUser(userData); 
                setIsLoading(false);
            } catch (error) {
                console.error('There was an error fetching the user data!', error);
            }
        };

        fetchUserData();
        return () => abortController.abort();
    }, []);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditUser({ ...editUser, [name]: value });
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('Token');
            await axios.patch(`${API_BASE_URL}/mri-users/${user.id}`, editUser, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            setUser({ ...editUser });
            setIsEditing(false); 
            toast.success('User Updated Successfully');
        } catch (error) {
            toast.error('Error Updating User');
        }
    };

    return (
        <>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="p-2">
                    <form>
                        <div className="row gx-4 mb-3">
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="fName">First Name</label>
                                <input type="text" className="form-control rounded-3" maxLength="15" id="fName" name="firstName" value={isEditing ? editUser.firstName : user.firstName} readOnly={!isEditing} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="lName">Last Name</label>
                                <input type="text" className="form-control rounded-3" maxLength="15" id="lName" name="lastName" value={isEditing ? editUser.lastName : user.lastName} readOnly={!isEditing} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="uName">User Name</label>
                                <input type="text" className="form-control rounded-3" maxLength="15" id="uName" name="userName" value={isEditing ? editUser.userName : user.userName} readOnly={!isEditing} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input type="text" className="form-control rounded-3" placeholder="Email" id="email" value={user.email} readOnly />
                            </div>
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="profession">Profession</label>
                                <input type="text" className="form-control rounded-3" maxLength="15" id="profession" name="profession" value={isEditing ? editUser.profession : user.profession} readOnly={!isEditing} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-2 mb-md-3">
                                <label className="form-label" htmlFor="contactNo">Contact Number</label>
                                <input type="text" className="form-control rounded-3" id="contactNo" name="contactNumber" value={isEditing ? editUser.contactNumber : user.contactNumber} readOnly={!isEditing} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            {!isEditing ? (
                                <button type="button" className="btn btn-light-warning btn-warning me-3" onClick={toggleEditMode}>Edit</button>
                            ) : (
                                <button type="button" className="btn btn-primary me-3" onClick={handleSave}>Save</button>
                            )}
                        </div>
                    </form>
                    <ToastContainer />
                </div>
            )}
        </>
    );
};

export default UserProfile;
