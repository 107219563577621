import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); 
  const navigate = useNavigate();
  const id = location.pathname.split('/').pop();
  const apitoken = localStorage.getItem('Token');
  const [visible, setVisible] = useState(true);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPasswordLoading(true);
    setVisible(true);
    try {
      const response = await fetch(`${API_BASE_URL}/mri-users/reset-password/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newPassword, confirmPassword })
      });
      const result = await response.json();
      if (response.status === 200 && result.msg === 'Password updated successfully') {
        toast.success('Password updated successfully');
        setPasswordLoading(false);
        setVisible(false);
        navigate('/login')
      } else {
        toast.error(result.msg);
      }
    } catch (error) {
      toast.error(result.msg);
    } finally {
      setPasswordLoading(false);
      setVisible(false);
    }
  };

  const backToLogin = () => {
    navigate('/login');
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div className="ResetPassword-bg"></div>
      <div className="container reset-form-container">
        <div className="p-md-5 p-3 w-lg-45 rounded-4" id="form-card">
          <h1 className="text-center text-light mb-2">Reset Password</h1>
          <p className="text-center text-light mb-4 fs-5">Set your new password</p>
          <div className="">
            <form className="forget-password-container" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="newPassword" className="form-label text-light">New Password:</label>
                    <input type="password" minLength="8" id="newPassword" name="newPassword" placeholder="Enter your new password" className="form-control rounded-3" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-4">
                    <label htmlFor="confirmPassword" className="form-label text-light">New Password Confirmation:</label>
                    <div className="input-group mb-3">
                      <input type={passwordVisible ? "text" : "password"} minLength="8" id="confirmPassword" name="confirmPassword" placeholder="Confirm your new password" className="form-control rounded-3 rounded-end-0" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                      <button className="btn btn-light" type="button" onClick={togglePasswordVisibility} ><FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} /></button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-primary waves-effect waves-light py-2" onClick={backToLogin}>Back to Login</button>
                    <button type="submit" className="btn btn-primary waves-effect waves-light w-25 py-2" >Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
};

export default ResetPassword;
