import React from "react";
import Empowerment from '../assets/icon/Empowerment.png';
import PrecisionDiagnosis from '../assets/icon/Precision Diagnosis.png';
import VolumeTracking from '../assets/icon/Volume Tracking.png'; 
import TimeSavingModel from '../assets/icon/Time-Saving AI Model.png';
import IntegrationWithRadiologist from '../assets/icon/Integration with Radiology.png';
import features1 from '../assets/images/new_images/features2.webp';
import features2 from '../assets/images/new_images/features.webp';
import features3 from '../assets/images/new_images/features1.webp';
import features4 from '../assets/images/new_images/features5.webp';
import features5 from '../assets/images/new_images/features4.webp';
import '/src/assets/css/Newstyling.css';



const Features = () => {
  return (
    <section className="department_area  section-padding2 about-area">

      <div className="container">
        {/* Section Title */}
        <div className="row">
          <div className="col-lg-12">
            <div className="section-tittle text-center mb-100">
              {/* <span>Features</span> */}
              <h2>Our Main Features</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="depart_ment_tab mb-30">
              {/* Tabs Buttons */}
              <ul className="nav" id="myTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active"id="home-tab" data-toggle="tab" href="#home"role="tab"aria-controls="home"aria-selected="true" >
                    <i><img src={Empowerment}alt="Download Icon" width="50" height="50" /></i>
                    <h3>Empowerment</h3>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link"id="profile-tab"data-toggle="tab"href="#profile"role="tab"aria-controls="profile" aria-selected="false" >
                    <i><img src={PrecisionDiagnosis} alt="Download Icon" width="50" height="50" /></i>
                    <h3>Precision Diagnosis</h3>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="contact-tab" data-toggle="tab"href="#contact"role="tab"aria-controls="contact" aria-selected="false">
                    <i><img src={VolumeTracking} alt="Download Icon" width="50" height="50" /></i>
                    <h3>Volume Tracking</h3>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="Astrology-tab"data-toggle="tab"href="#Astrology"role="tab" aria-controls="Astrology"aria-selected="false">
                    <i><img src={TimeSavingModel} alt="Download Icon" width="50" height="50" /></i>
                    <h3>Time-Saving AI Model</h3>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="Neuroanatomy-tab" data-toggle="tab" href="#Neuroanatomy" role="tab" aria-controls="Neuroanatomy"aria-selected="false" >
                    <i><img src={IntegrationWithRadiologist} alt="Download Icon" width="50" height="50" /></i>
                    <h3>Integration with Radiology</h3>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="Blood-tab"
                    data-toggle="tab"
                    href="#Blood"
                    role="tab"
                    aria-controls="Blood"
                    aria-selected="false"
                  >
                    <i className="flaticon-cell"></i>
                    <h4>Blood Screening</h4>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="dept_main_info white-bg">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              {/* Tab Content */}
              <div className="row align-items-center no-gutters">
                <div className="col-lg-7">
                  <div className="dept_info">
                    {/* <span>Features</span> */}
                    <h3>Empowerment</h3>
                    <p>
                    Timely insights for early intervention in Alzheimer’s care.
                    </p>
                    {/* <a href="#" className="dep-btn">
                      Appointment<i className="ti-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dept_thumb">
                    <img src={features1} alt="Empowerment" />
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat for other tabs */}
            {/* Profile Tab */}
            <div className="tab-pane fade"id="profile"role="tabpanel"aria-labelledby="profile-tab" >
              <div className="row align-items-center no-gutters">
                <div className="col-lg-7">
                  <div className="dept_info">
                    <h3>Precision Diagnosis</h3>
                    <p>
                    AI-based hippocampus segmentation for accurate monitoring.
                    </p>
                    {/* <a href="#" className="dep-btn">
                      Appointment<i className="ti-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dept_thumb">
                  <img src={features2} alt="Precision Diagnosis" />
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade"id="contact"role="tabpanel" aria-labelledby="contact-tab"
            >
              <div className="row align-items-center no-gutters">
                <div className="col-lg-7">
                  <div className="dept_info">
                    <h3>Volume Tracking </h3>
                    <p>
                    Detailed hippocampus volume analysis for disease progression.
                    </p>
                    {/* <a href="#" className="dep-btn">
                      Appointment<i className="ti-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dept_thumb">
                    <img src={features3} alt="Volume Tracking" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="Astrology"
              role="tabpanel"
              aria-labelledby="Astrology-tab"
            >
              <div className="row align-items-center no-gutters">
                <div className="col-lg-7">
                  <div className="dept_info">
                    <h3>Time-Saving AI Model</h3>
                    <p>
                    Minimize the time taken for analysis with an efficient AI backend that processes Images.
                    </p>
                    {/* <a href="#" className="dep-btn">
                      Appointment<i className="ti-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dept_thumb">
                    <img src={features4} alt="Time Saving AI Model" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="Neuroanatomy"
              role="tabpanel"
              aria-labelledby="Neuroanatomy-tab"
            >
              <div className="row align-items-center no-gutters">
                <div className="col-lg-7">
                  <div className="dept_info">
                    <h3>Integration with Radiology</h3>
                    <p>
                    Seamless workflows for clinicians and researchers.
                    </p>
                    {/* <a href="#" className="dep-btn">
                      Appointment<i className="ti-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dept_thumb">
                    <img src={features5} alt="Integration with Radiology" />
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat similar structure for ENT Specialists, Astrology, Neuroanatomy, Blood Screening */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
