import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from "../assets/images/logo/Alzevitalogo.webp"; // Importing logo image
import { useNavigate } from 'react-router-dom';
import "../assets/css/Login.css";
import '../assets/new_css/style.css';
import '../assets/new_css/animated-headline.css';
import '../assets/new_css/bootstrap.min.css';


const NewHeader = () => {
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        navigate('../login');
    };

    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

    return (
        <header>
            <link rel="stylesheet" href="/css/bootstrap.min.css" />
            <link rel="stylesheet" href="/css/animated-headline.css" />
            <link rel="stylesheet" href="/src/assets/new_css/style.css" />
            <link rel="stylesheet" href="/css/style.css"/>
            <div className="header-area">
                <div className="main-header header-sticky"> 
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            {/* Logo */}
                            <div className="col-xl-2 col-lg-2 col-md-1">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10">
                                <div className="menu-main d-flex align-items-center justify-content-end">
                                    {/* Main-menu */}
                                    <div className="main-menu f-right d-none d-lg-block">
                                        <nav>
                                            <ul id="navigation">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/AboutUs">About Us</Link></li>
                                                <li><Link to="/Radiologist">Radiologist</Link></li>
                                                <li><Link to="/ContactUs">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    {/* Login button (desktop) */}
                                    {!isMobileMenuOpen && ( // Hide button when mobile menu is open
                                        <li className="nav-item d-none d-lg-block">
                                            <button className="btn btn-primary py-1 px-4" onClick={handleLogin}> Log In </button>
                                        </li>
                                    )}
                                </div>
                            </div>
                            {/* Mobile Menu */}
                            <div className="mobile_menu d-block d-lg-none">
                                <button className="hamburger" onClick={toggleMobileMenu}>
                                    <i className="fa-solid fa-bars"></i> {/* FontAwesome Hamburger Icon */}
                                </button>
                                {isMobileMenuOpen && (
                                    <nav>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/AboutUs">About Us</Link></li>
                                            <li><Link to="/Radiologist">Radiologist</Link></li>
                                            <li><Link to="/ContactUs">Contact</Link></li>
                                            {/* Login button inside mobile menu */}
                                            <li>
                                                <button
                                                    className="btn btn-primary py-1 px-4"
                                                    onClick={handleLogin}>
                                                    Log In
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NewHeader;
