import React, { useEffect, useState } from 'react';
// import './assets/css/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewHeader from './components/NewHeader';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [visible, setVisible] = useState(true);
  const [loginLoading, setLoginLoading] = useState(true);
  const navigate = useNavigate();

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  const validateUsername = () => {
    if (!username.trim()) {
      setUsernameError('Username is required');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const validatePassword = () => {
    if (!password.trim()) {
      setPasswordError('Password is required');
      return false;
    }
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setVisible(true);
    const isUsernameValid = validateUsername();
    const isPasswordValid = validatePassword();

    if (!isUsernameValid || !isPasswordValid) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          rememberMe: false,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.data && responseData.data.token !== null) {
          const token = responseData.data.token;
          localStorage.setItem('Token', token);

          // Decode the token to get the role
          const decodedToken = jwtDecode(token);
          const userRole = decodedToken.roles;

          console.log('Auth Token Set-', token);

          setLoginLoading(false);
          setVisible(false);
          if (userRole === 'ROLE_ADMIN') {
            navigate('/admin');
          } else {
            //toast.success('Login Successfully!');
            navigate('/alzheimermain');
          }
        } else {
          toast.warn('Admin Approval Required!');
          console.error('Login failed: Token is null');
        }
      } else {
        toast.error('Bad credentials!');
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoginLoading(false);
      setVisible(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  return (
    <div>
      <NewHeader />

      <div className="login-bg">
        <div className="login-container-bg"></div>
        <div className="login-form-container">
          {/* begin:: card */}
          <div className="container">

            <div className="w-75 mx-auto">
              <div className="">

                <div className="row overflow-hidden rounded-5 " >
                  <div className="col-6 img-login px-0 h-auto bg-login-page">
                    {/* <img src="/src/assets/images/new_images/features.webp" className='img-fluid'></img> */}
                  </div>

                  <div className="col-6 p-5 " id="form-card">
                    {/* begin:: */}

                    <div className="w-90 mx-auto mt-35">

                      <div className="mb-35">
                        <h1 className="text-center text-light mb-2 fs-1">SIGN INTO YOUR ACCOUNT</h1>

                      </div>
                      <div className="">
                        <form className="signup-form" onSubmit={handleLogin}>
                          <div className="mb-15">
                            <div className="">
                              <div className="mb-3 custom_input">
                                <label className="form-label text-light fs-3" htmlFor="username">Username</label>
                                <input type="email" className="form-control rounded-3 " placeholder="Enter Username" maxLength="50" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                {usernameError && <div className="text-danger">{usernameError}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="">
                              <div className="mb-3">
                                <label className="form-label text-light fs-3" htmlFor="userpassword">Password</label>

                                <div className="input-group custom_input">
                                  <input type={passwordVisible ? "text" : "password"} className="form-control rounded-3 rounded-end-0 " placeholder="Enter Password" minLength="8" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                  <button className="btn btn-light" type="button" onClick={togglePasswordVisibility} ><FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} /></button>
                                </div>
                                {passwordError && <div className="text-danger">{passwordError}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-4">
                            <div className="mb-3 ">
                              <input type="checkbox" className="form-check-input " id="customControlInline" />
                              <label className="form-check-label text-light" htmlFor="customControlInline">&nbsp; Remember me</label>
                            </div>
                          </div>

                          <div className="col-12 mb-3 text-center">
                            <button className="btn btn-primary w-md waves-effect waves-light w-50 py-2 fs-5 rounded-4" type="submit">Log In</button>
                          </div>

                          <div className="col-12 mb-3 text-center">
                            <Link to="/forgetpassword" className="text-decoration-underline text-light fs-5">Forget Password?</Link>
                          </div>

                          {/* Login link */}
                          <div className="">
                            <p className="text-center text-light fs-5">Don't have an account? <Link to="/Signup" className="text-decoration-underline text-light">Sign Up</Link> for free.</p>
                          </div>
                          {/* <div className="">
          <p className="text-center text-light fs-5">Visit our <Link to="/" className="text-decoration-underline text-light">home</Link> page.</p>
        </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* end:: card */}
        </div>
        <ToastContainer />
        <Loader />
      </div>
    </div>
  );
};

export default Login;
