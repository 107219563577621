import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from './config';

const UserProfileData = ({ userId }) => {
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        profession: '',
        contactNumber: '',
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('Token'); 
                const response = await axios.get(`${API_BASE_URL}/mri-users/user-detail-id/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const userData = response.data.data;
                setUser({
                    firstName: userData.firstName || '',
                    lastName: userData.lastName || '',
                    userName: userData.userName || '',
                    email: userData.email || '',
                    profession: userData.profession || '',
                    contactNumber: userData.contactNumber || ''
                });
            } catch (error) {
                console.error('There was an error fetching the user data!', error);
            }
        };

        fetchUserData();
    }, [userId]);

    return (
        <>
            <div className="p-2">
                <form>
                    <div className="row gx-4 mb-3">
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="fName">First Name</label>
                            <input type="text" className="form-control rounded-3" placeholder="First Name" id="fName" value={user.firstName} readOnly />
                        </div>
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="lName">Last Name</label>
                            <input type="text" className="form-control rounded-3" placeholder="Last Name" id="lName" value={user.lastName} readOnly />
                        </div>
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="UName">User Name</label>
                            <input type="text" className="form-control rounded-3" placeholder="User Name" id="uName" value={user.userName} readOnly />
                        </div>
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input type="text" className="form-control rounded-3" placeholder="Email" id="email" value={user.email} readOnly />
                        </div>
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="profession">Profession</label>
                            <input type="text" className="form-control rounded-3" placeholder="Profession" id="profession" value={user.profession} readOnly />
                        </div>
                        <div className="col-md-6 mb-2 mb-md-3">
                            <label className="form-label" htmlFor="contactNo">Contact Number</label>
                            <input type="text" className="form-control rounded-3" placeholder="Contact Number" id="contactNo" value={user.contactNumber} readOnly />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-light-warning btn-warning me-3">Edit</button>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default UserProfileData;
