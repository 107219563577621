import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './assets/css/Home.css';
import './assets/dropzone/plugins.bundle.css';
import NiiVue from './Niivue';
import Footer from './Footer';
import NiiVueWrapper from './NiiVueWrapper';
import API_BASE_URL from './config';
import UserHeader from './UserHeader';
import './assets/css/Footer.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Enhance = () => {

  const [visible, setVisible] = useState(true);
  const fileInputRef = useRef(null);
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnhanceVolumes] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isImageEnhanced, setIsImageEnhanced] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [id, setId] = useState(null);
  const [isDownloadEnhanceImage, setIsDownloadEnhanceImage] = useState(false);
  const apitoken = localStorage.getItem('Token');
  const [uploading, setUploading] = useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  const handleDemo = async () => {
    try {
      setDemoLoading(true);
      setVisible(true);

      setTimeout(() => {
        const demoImage = [
          {
            url: '/demoimage/I19378.nii.gz',
          },
        ];
        setVolumes(demoImage);

        const demoEnhanceImage = [
          {
            url: '/demoimage/enhanced_image.nii',
          },
        ];
        setEnhanceVolumes(demoEnhanceImage);
        setIsDownloadEnhanceImage(true);
        setIsDemo(true);
        setVisible(false);
        setDemoLoading(false);
      }, 100);
    } catch (error) {
      console.error('Error in demo:', error);
      toast.error('Failed to load demo. Please try again later.');
      setVisible(false);
      setDemoLoading(false);
    }
  };

  const handleImageUpload = async () => {
    if (volumes.length > 0) {
      toast.warn('Clear the current image to proceed with a new upload.');
      return;
    }

    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      setUploading(true);
      setVisible(true);

      const uploaded = fileInputRef.current.files[0];
      const validFileExtensions = ['nii', 'nii.gz'];
      const fileName = uploaded.name.toLowerCase();
      const isValidExtension = validFileExtensions.some(extension => fileName.endsWith(`.${extension}`));

      if (!isValidExtension) {
        toast.error('Please upload a valid .nii or .nii.gz file.');
        setUploading(false);
        return;
      }

      setUploadedFile(uploaded);

      try {
        const formData = new FormData();
        formData.append('file', uploaded);

        const response = await fetch(`${API_BASE_URL}/mri-request/upload-mri`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apitoken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        if (data.status === 200) {
          const newId = data.data.id;
          setId(newId);

          const NVRVolumeImage = [
            {
              url: `${API_BASE_URL}/mri-request/view-mri/${newId}/mri.nii.gz`,
            },
          ];
          setVolumes(NVRVolumeImage);
          setUploading(false);
          toast.success('File Uploaded');
        } else {
          toast.error('Failed to upload image');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image. Please try again later.');
      } finally {
        setUploading(false);
        setVisible(false);
      }
    } else {
      toast.warn('Please choose a file to upload.');
    }
  };

  useEffect(() => {
    setIsImageEnhanced(volumes.length > 0 && enhanceVolumes.length > 0);
    setIsUpload(volumes.length > 0 && enhanceVolumes.length > 0);
  }, [volumes.length, enhanceVolumes.length]);

  const enhanceImage = () => {
    if (volumes.length > 0) {
      setEnhancing(true);
      setVisible(true);
      setIsUpload(true);
      setIsImageEnhanced(true);
      fetch(`${API_BASE_URL}/mri-request/enhance-mri/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apitoken}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to enhance image');
          }
          return response.json();
        })
        .then(data => {
          const parsedData = JSON.parse(data.data);
          if (parsedData.success === "Image enhanced successfully") {
            toast.success('Image Enhanced Successfully');
            const NVRVolumeEnhanceImage = [
              {
                url: `${API_BASE_URL}/mri-request/view-enhance-mri/${id}/enhance.nii.gz`,
              },
            ];
            setEnhanceVolumes(NVRVolumeEnhanceImage);
            setEnhancing(false);
            setVisible(false);
          } else {
            toast.error('Enhanced Failed');
          }
        })
        .catch(error => {
          toast.error('Failed to enhance image. Please try again later.');
        })
        .finally(() => {
          setEnhancing(false);
          setVisible(false);
        });
    } else {
      toast.warn('Please Upload Image For Enhancement');
    }
  };

  const clearElement = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    clearUploadedFile();
  };

  const clearUploadedFile = async () => {
    toast.success('Image Cleared');
    setVolumes([]);
    setEnhanceVolumes([]);
    setIsDownloadEnhanceImage(false);
    setIsDemo(false);
    setIsUpload(false);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      <div className="d-flex justify-content-around">
        <span>{file.path}</span>
        <span className="ms-auto">{(file.size / (1024 * 1024)).toFixed(2)} mb</span>
      </div>
    </li>
  ));

  const downloadEnhanceImage = () => {
    if (volumes.length > 0) {
      const url = `${API_BASE_URL}/mri-request/view-enhance-mri/${id}/enhance.nii.gz`;
  
      setDownloading(true); 
      setVisible(true);
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mri-enhance.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Enhance Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the enhanced image:', error);
          toast.error('Error downloading the enhanced image');
        })
        .finally(() => {
          setDownloading(false);
          setVisible(false);
        });
    } else {
      toast.warn('No Image Loaded');
      setDownloading(false); 
      setVisible(false); 
    }
  };
  

  return (
    <div>
      <div className="home-container-bg"></div>
      <UserHeader />
      <div className="home-container blur-bg-container" style={{ color: '#fff' }}>
        <div className="">
          <div className="tools w-23 p-4 d-none" id="enhancingTools">
            <div className='d-flex flex-column'>
            </div>
          </div>
          <div className="home-content">

            <div className="container">
              <div className="py-md-10">

                <div className="">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center text-light">Welcome To <br /> MRI Image Processing System</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="w-md-50">
                          <input className="form-control dropFile form-control-lg" type="file" id="formFile" ref={fileInputRef} accept=".nii,.nii.gz" />
                          <p className="p-2">Please upload MRI image for enhancement.(format should be .nii)</p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mb-4">
                        <div className="me-3">
                          <button className="btn btn-1 btn-135" onClick={handleImageUpload} disabled={isUpload}>
                            Upload <span><i className="fa fa-upload fs-5"></i></span>
                          </button>
                          {uploading} 
                        </div>
                        <div>
                          <button className="btn btn-1 btn-135" onClick={clearElement}>Clear</button>
                        </div>

                        {/* Demo button */}
                        <div className="ms-3">
                          <button className="btn btn-1 btn-135" onClick={handleDemo} disabled={isDemo}>
                            Demo {demoLoading} 
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                  {volumes.length > 0 && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                    <button className="btn btn-1 btn-135 mt-2" onClick={enhanceImage} disabled={isImageEnhanced}>
                      Enhance image
                    </button>
                    {enhancing} 
                  </div>
                  {enhanceVolumes.length > 0 && <NiiVue volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please enhance file" />}

                  <div className="mt-3">
                    <button className="btn btn-1 mt-2" onClick={downloadEnhanceImage} disabled={isDownloadEnhanceImage}>
                      Download Enhanced Image {downloading}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
}

export default Enhance;
