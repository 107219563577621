import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import API_BASE_URL from './config';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const columns = (handleView, handleDownload, handleDelete) => [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'fileName', headerName: 'Mri File Name', width: 250, sortable: true },
    { field: 'uploadDate', headerName: 'Upload Date', width: 180, sortable: true },
    { field: 'enhanceImage', headerName: 'Enhance Image', width: 250, sortable: true },
    { field: 'enhanceDate', headerName: 'Enhance Date', width: 180, sortable: true },
    { field: 'status', headerName: 'Status', width: 120, sortable: true },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
            <div className="d-flex justify-content-center mt-2">
                <Tooltip title="View" arrow placement="top">
                    <button type="button" className="btn btn-sm btn-light-primary w-35px h-35px ms-2" data-bs-toggle="tooltip" data-bs-custom-className="tooltip-inverse" data-bs-placement="top" data-bs-original-title="View" onClick={() => handleView(params.row)} >
                        <i className="fa fa-eye fs-6"></i>
                    </button>
                </Tooltip>

                <Tooltip title="Download" arrow placement="top">
                    <button type="button" className="btn btn-sm btn-light-primary w-35px h-35px ms-2" data-bs-toggle="tooltip" data-bs-custom-className="tooltip-inverse" data-bs-placement="top" data-bs-original-title="Download" onClick={() => handleDownload(params.row)}>
                        <i className="fa fa-download fs-6"></i>
                    </button>
                </Tooltip>

                <Tooltip title="Delete" arrow placement="top">
                    <button type="button" className="btn btn-sm btn-light-danger w-35px h-35px ms-2" data-bs-toggle="tooltip" data-bs-custom-className="tooltip-inverse" data-bs-placement="top" data-bs-original-title="Delete" onClick={() => handleDelete(params.row)}>
                        <i className="fa fa-trash fs-6"></i>
                    </button>
                </Tooltip>
            </div>
        ),
    },
];

function EnhanceDataTable() {
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [visible, setVisible] = useState(true);

    const Loader = () => (
        <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
          <div className="section--white">
            <div className="container-loader">
              <div className="slice"></div>
              <div className="slice"></div>
              <div className="slice"></div>
              <div className="slice"></div>
              <div className="slice"></div>
              <div className="slice"></div>
            </div>
          </div>
        </div>
      );

      useEffect(() => {
        const timeout = setTimeout(() => {
          setVisible(false);
        }, 100);
        return () => clearTimeout(timeout);
      }, []);
    
      const footerStyle = {
        position: "relative",
        zIndex: "99",
      }

    const navigate = useNavigate();

    const handleView = async (row) => {
        navigate(`/enhanceview/${row.id}`);
    };

    const handleDownload = async (row) => {
        setDownloading(true); 
        setVisible(true);
        const apitoken = localStorage.getItem('Token');

        if (!apitoken) {
            console.error('No API token found in local storage');
            setDownloading(false); 
            return;
        }

        try {
            const response = await axios.get(`${API_BASE_URL}/mri-request/view-enhance-mri-id/${row.id}`, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                },
                responseType: 'blob'
            });

            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : `${row.fileName}`;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            toast.success('Enhance Image Downloaded!');
            setDownloading(false); 
            setVisible(false);
        } catch (error) {
            console.error('There was an error downloading the file!', error);
            toast.error('Error downloading file');
        } finally {
            setDownloading(false); 
            setVisible(false);
        }
    };

    const handleDelete = async (row) => {
        setDownloading(true); 
        setVisible(true);
        const apitoken = localStorage.getItem('Token');

        if (!apitoken) {
            console.error('No API token found in local storage');
            return;
        }

        try {
            await axios.delete(`${API_BASE_URL}/mri-request/delete-mri/${row.id}`, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                }
            });
            setRows(prevRows => prevRows.filter(item => item.id !== row.id));
            setDownloading(false); 
            setVisible(false);
            toast.success('Enhance Data Deleted Successfully');
        } catch (error) {
            console.error('There was an error deleting the file!', error);
        }finally {
            setDownloading(false); 
            setVisible(false);
        }
    };

    const fetchData = async () => {
        try {
            const apitoken = localStorage.getItem('Token');
            if (!apitoken) {
                console.error('No API token found in local storage');
                return;
            }

            const response = await axios.get(`${API_BASE_URL}/mri-request/history`, {
                headers: {
                    Authorization: `Bearer ${apitoken}`
                },
                params: {
                    page: page,
                    size: pageSize
                }
            });

            const { data, totalPages } = response.data;

            const mappedData = data.map(item => ({
                id: item.id,
                fileName: item.imageName,
                uploadDate: new Date(item.uploadedDate).toLocaleDateString(),
                enhanceImage: item.enhanceImageName || '',
                enhanceDate: item.enhanceImageDate ? new Date(item.enhanceImageDate).toLocaleDateString() : '',
                status: item.status || ''
            }));

            setRows(mappedData);
            setTotalPages(totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, pageSize]);

    const handleChangePageSize = (event) => {
        const newPageSize = parseInt(event.target.value);
        setPageSize(newPageSize);
        setPage(0);
    };

    const navigateToPage = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleFirstPage = () => {
        setPage(0);
    };

    const handlePrevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
        }
    };

    const handleLastPage = () => {
        setPage(totalPages - 1);
    };

    return (
        <div>
            <div style={{ marginTop: '10px' }}>
                <div className="d-flex mb-3">
                    <div>
                        <Button disabled={page === 0} onClick={handleFirstPage}>First</Button>
                        <Button disabled={page === 0} onClick={handlePrevPage}>Previous</Button>
                    </div>
                    <div>
                        <select className="form-select border-primary-subtle w-md-80px" aria-label="Default select example" onChange={handleChangePageSize} value={pageSize}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                        </select>
                    </div>
                    <div>
                        <Button disabled={page >= totalPages - 1} onClick={handleNextPage}>Next</Button>
                        <Button disabled={page >= totalPages - 1} onClick={handleLastPage}>Last</Button>
                    </div>
                </div>
            </div>

            <DataGrid
                rows={rows}
                columns={columns(handleView, handleDownload, handleDelete)}
                pagination
                paginationMode="client"
                page={page}
                className="rounded-2 data-table-container"
            />
                <Loader />
             <ToastContainer />
        </div>
    );
}

export default EnhanceDataTable;
