import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import NiiVue from './Niivue';
import NiiVueWrapper from './NiiVueWrapper';
import API_BASE_URL from './config';
import './assets/css/Home.css';
import './assets/dropzone/plugins.bundle.css';
import UserHeader from './UserHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EnhanceView = () => {
  const location = useLocation();
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnhanceVolumes] = useState([]);
  const apitoken = localStorage.getItem('Token');
  const [downloading, setDownloading] = useState(false);
  const [visible, setVisible] = useState(true);
  const id = location.pathname.split('/').pop();

  const Loader = () => (
    <div id="loader" className={`spinner ${visible ? 'visible' : 'hidden'}`}>
      <div className="section--white">
        <div className="container-loader">
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  const footerStyle = {
    position: "relative",
    zIndex: "99",
  }

  useEffect(() => {
    if (!id) {
      console.error('No ID provided.');
      return;
    }

    fetch(`${API_BASE_URL}/mri-request/view-mri/${id}/mri.nii.gz`)
      .then((response) => {
        if (response.status === 200) {
          setVolumes([
            {
              url: `${API_BASE_URL}/mri-request/view-mri/${id}/mri.nii.gz`,
            },
          ]);
        }
      })
      .catch((error) => console.error('Error fetching MRI image:', error));
  }, [id, apitoken]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/mri-request/view-enhance-mri/${id}/enhance.nii.gz`)
      .then((response) => {
        if (response.status === 200) {
          setEnhanceVolumes([
            {
              url: `${API_BASE_URL}/mri-request/view-enhance-mri/${id}/enhance.nii.gz`,
            },
          ]);
        }
      })
      .catch((error) => console.error('Error fetching enhanced MRI image:', error));
  }, [id, apitoken]);

  const downloadEnhanceImage = () => {
    if (volumes.length > 0) {
      const url = `${API_BASE_URL}/mri-request/view-enhance-mri/${id}/enhance.nii.gz`;
  
      setDownloading(true); 
      setVisible(true);
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mri-enhance.nii.gz');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success('Enhance Image Downloaded');
        })
        .catch(error => {
          console.error('Error downloading the enhanced image:', error);
          toast.error('Error downloading the enhanced image');
        })
        .finally(() => {
          setDownloading(false);
          setVisible(false);
        });
    } else {
      toast.warn('No Image Loaded');
      setDownloading(false); 
      setVisible(false); 
    }
  };

  return (
    <div>
      <div className="home-container-bg"></div>
      <UserHeader />
      <div className="home-container blur-bg-container" style={{ color: '#fff' }}>
        <div className="">
          <div className="tools w-23 p-4 d-none" id="enhancingTools">
            <div className='d-flex flex-column'></div>
          </div>
          <div className="home-content">
            <div className="container">
              <div className="py-md-4">
                <div className="">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center text-light">Enhance View <br /> MRI Image Processing System</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="w-md-50">
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mb-4">
                        <div className="me-3">
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {volumes.length > 0 && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                  </div>
                  {enhanceVolumes.length > 0 && <NiiVueWrapper volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVueWrapper volumes={[]} loadingText="No Data Available, please enhance file" />}
                  <div className="mt-3">
                    <button className="btn btn-1 btn-135 mt-2" onClick={downloadEnhanceImage}>Download Enhanced Image {downloading}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
};

export default EnhanceView;
