import React, { useState } from 'react';
import './assets/css/ResetPassword.css';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const apitoken = localStorage.getItem('Token');

  const backToLogin = () => {
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/mri-users/change-password`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apitoken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ oldPassword, newPassword })
      });
      const result = await response.json();
      if (response.status === 200 && result.msg === 'Password changed successfully') {
        toast.success('Password changed successfully');
        navigate('/login')
      } else {
        toast.error(result.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    }
  };

  return (
    <div>
      <div className="ResetPassword-bg"></div>
      <div className="container reset-form-container">
        <div className="p-md-5 p-3 w-lg-45 rounded-4" id="form-card">
          <h1 className="text-center text-light mb-2">Change Password</h1>
          <p className="text-center text-light mb-4 fs-5">Set your new password</p>
          <div className="">
            <form className="forget-password-container" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="oldPassword" className="form-label text-light">Old Password:</label>
                    <input type="password" minLength="8" id="oldPassword" name="oldPassword" placeholder="Enter your old password" className="form-control rounded-3" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-4">
                    <label htmlFor="newPassword" className="form-label text-light">New Password:</label>
                    <input type="password" minLength="8" id="newPassword" name="newPassword" placeholder="Enter your new password" className="form-control rounded-3" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-primary w-md waves-effect waves-light py-2" onClick={backToLogin}>Back to Login</button>
                    <button type="submit" className="btn btn-primary w-md waves-effect waves-light w-25 py-2">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
