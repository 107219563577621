import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import AdminDataTable from './AdminDataTable';
import './assets/css/Home.css';
import topiaLogo from './assets/images/logo/Topia-Life-Sciences.png';
import { useNavigate } from 'react-router-dom';
import UserProfile from './UserProfile';
import API_BASE_URL from './config';
import Footer from './Footer';

const Admin = () => {

    const navigate = useNavigate();
    const apitoken = localStorage.getItem('Token');
    const [userDetails, setUserDetails] = useState({ firstName: '', email: '' });
    const [modalType, setModalType] = useState(null);

    useEffect(() => {
        if (apitoken) {
            fetchUserDetails(apitoken).then(data => {
                if (data) {
                    setUserDetails({ firstName: data.firstName, email: data.email });
                }
            });
        }
    }, [apitoken]);

    const fetchUserDetails = async (apitoken) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mri-users/user-detail-id`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apitoken}`,
                },
            });
            const result = await response.json();
            if (result.status === 0) {
                return result.data;
            } else {
                console.error("Failed to fetch user details:", result.msg);
                return null;
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
            return null;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('Token');
        navigate('/login');
    };

    const handleHome = () => {
        navigate('/')
    }

    const handlePassword = () => {
        navigate('/resetpassword')
    }

    const handleClose = () => setModalType(null);
    const handleShow = (type) => setModalType(type);

    return (
        <div>
            <div className="user-history-container"></div>
            {/* Header begins */}
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand">
                        <img src={topiaLogo} alt="logo" className="logo-img" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown-container">
                                <div className="d-flex align-items-center justify-content-between border border-dashed border-gray-300 rounded p-2 w-220px">
                                    <div className="me-3 user-details-container">
                                        <a className="text-dark text-hover-primary fs-6 fw-bold">{userDetails.firstName}</a>
                                        <a className="fs-6 fw-bold d-block text-primary user-details-email">{userDetails.email}</a>
                                    </div>
                                    <span className="svg-icon svg-icon-2 svg-icon-gray-500 pt-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </div>

                                {/* Dropdown begins */}
                                <div className="dropdown-content menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color py-2 fs-6 w-275px">
                                    <div className="menu-item">
                                        <a className="menu-link" onClick={() => handleShow('userProfile')}>
                                            <i className="fa fa-user me-2"></i><span className="menu-text">Profile</span>
                                        </a>
                                        <a className="menu-link" onClick={handlePassword}>
                                            <i className="fa fa-lock me-2"></i><span className="menu-text">Change Password</span>
                                        </a>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="menu-item">
                                        <a className="menu-link sign-out-link" onClick={handleLogout}> <i className="fa fa-sign-out-alt me-2"></i> Sign Out</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="blur-bg-container">
                <div className="wrapper container">
                    <div className="content-wrapper py-3">
                        <div className="pb-3">
                            <div className="d-flex align-items-center">
                                <ol className="breadcrumb mb-0">
                                    <li className="">
                                        <a className="text-light" href="#"><i className="fa fa-home"></i></a> <i className="fa fa-angle-right mx-2 text-light" aria-hidden="true"></i>
                                    </li>
                                    <li className="">
                                        <a className="text-light" href="#">History</a>
                                    </li>
                                </ol>
                                <div className="ms-auto">
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="d-flex align-items-center card-title">
                                    <span className="fs-5 fw-bold">User Details</span>
                                </h3>
                            </div>
                            <div className="card-body">
                                <AdminDataTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header end */}

            {/* user profile modal */}
            <Modal show={modalType === 'userProfile'} onHide={handleClose} dialogClassName="modal-lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserProfile />
                </Modal.Body>
            </Modal>

            <Footer />
        </div>
    );
};

export default Admin;
