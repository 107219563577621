import React,{useEffect } from 'react';
import NewHeader from './components/NewHeader';
import NewFooter from './components/NewFooter';
import './assets/new_css/animated-headline.css';
import './assets/new_css/style.css';
import './assets/new_css/bootstrap.min.css';
import '/src/assets/css/Newstyling.css';
import aboutUs1 from './assets/images/new_images/gallery1.webp';
import aboutUs2 from './assets/images/new_images/gallery1.webp';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <NewHeader />
      
      {/* Slider Area */}
      <div className="slider-area2 aboutus_banner">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>About Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}

      {/* About Section Start */}
      <div className="about-area section-padding2 ">
        <div className="container">

        <p className="pb-5 pt-0"><i>"Through the advancement of our platforms, we will build a fully integrated data economy. At Topia Life Sciences, we are passionate about improving health outcomes for people around the world."<b> - Kamlesh Patel, Founder.</b></i></p>
        <hr/>
          {/* Section 1: Image on the Right */}
          <section className="row align-items-center mb-5 part_1">
            <div className="col-lg-7 pr-85 ">
              <div className="about">
                <h3>Our Vision</h3>
                <p>
                We are changing the face of Alzheimer’s care with our AI technology. Our goal is to enable early detection and precise monitoring of Alzheimer’s so patients and healthcare professionals can act early. By using AI and the latest neuroimaging, we provide accuracy and consistency in diagnostics to improve patient outcomes and quality of life. Trust Alzevita to change how we understand, detect and care for Alzheimer’s one step at a time.
                </p>
                <p>
                Our MedTech platform is focused on neurodegenerative disease diagnostics. Alzevita gives clinicians the tools to identify and track key biomarkers such as hippocampal volume which is critical in the early stages of Alzheimer’s. Our approach supports early intervention and ongoing care for patients and providers.
                </p>
              </div>
            </div>
            <div className="col-lg-5 ">
              <img src={aboutUs1} alt="Our Mission" className="img-fluid rounded-4"/>
            </div>
          </section>

          {/* Section 2: Image on the Left */}
          <section className="row align-items-center part_2">
            <div className="col-lg-7 order-lg-2 pl-85">
              <div className="about">
                <h3>Our Technology</h3>
                <p>
                Alzevita’s proprietary platform uses AI algorithms to automatically segment and volumetrically analyze the hippocampus, the region of the brain responsible for memory and learning which is often the first to be affected by Alzheimer’s. This ensures objective and reproducible measurements so clinicians have the data to diagnose, monitor and personalize treatments. Alzevita enables a more informed and personalized approach to Alzheimer’s care.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-1 ">
              <img src={aboutUs2}a lt="Our Technology" className="img-fluid rounded-4"/>
            </div>
          </section>
        </div>
      </div>
      {/* About Section End */}

      <NewFooter />
    </div>
  );
};

export default AboutUs;
