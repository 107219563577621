import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './assets/css/Home.css';
import './assets/dropzone/plugins.bundle.css';
import Footer from './Footer';
import NiiVue from './Niivue';
import NiiVueWrapper from './NiiVueWrapper';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import Swal from 'sweetalert2';

// header
import UserHeader from './UserHeader';

const Home = () => {

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [volumes, setVolumes] = useState([]);
  const [enhanceVolumes, setEnhanceVolumes] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isImageEnhanced, setIsImageEnhanced] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const apitoken = localStorage.getItem('Token');

  useEffect(() => {
    fetch(`${API_BASE_URL}/mri-request/view-mri/mri.nii.gz?token=${apitoken}`,
      {
      }).then((response) => {
        if (response.status == 200) {
          let volumes = [
            {
              url: `${API_BASE_URL}/mri-request/view-mri/mri.nii.gz?token=${apitoken}`,
            },
          ];
          setVolumes(volumes);
        }
      })
  }, [])

  useEffect(() => {
    fetch(`${API_BASE_URL}/mri-request/view-enhance-mri/enhance.nii.gz?token=${apitoken}`,
      {
      }).then((response) => {
        if (response.status == 200) {
          let enhanceVolumes = [
            {
              url: `${API_BASE_URL}/mri-request/view-enhance-mri/enhance.nii.gz?token=${apitoken}`,
            },
          ];
          setEnhanceVolumes(enhanceVolumes);
        }
      })
  }, [])

  const handleImageUpload = async () => {
    if(volumes.length > 0){
     
      Swal.fire({
        icon: 'warning',
        title: 'Clear Image!',
        text: 'To proceed with a new image upload, please clear the current image.',
      });
    }else{
    if (fileInputRef.current && fileInputRef.current.files) {
      const uploaded = fileInputRef.current.files[0];
      setUploadedFile(uploaded);

      try {
        const formData = new FormData();
        formData.append('file', uploaded);

        const response = await fetch(`${API_BASE_URL}/mri-request/upload-mri`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apitoken}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        if (data.status === 200) {
          const NVRVolumeImage = [
            {
              url: `${API_BASE_URL}/mri-request/view-mri/mri.nii.gz?token=${apitoken}`,
            },
          ];
          setVolumes(NVRVolumeImage);
          if (data.data && data.data.image) {
            const blob = b64toBlob(data.data.image, 'image/jpeg');
            const imageUrlFromBlob = URL.createObjectURL(blob);
            setImageURL(imageUrlFromBlob);
          } else {
            console.error('Image data is not available');
          }
        } else {
          console.error('Failed to fetch image URL');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.error("File input reference is null or files array is empty");
    }
  }
  };

  useEffect(() => {
    setIsImageEnhanced(volumes.length > 0 && enhanceVolumes.length > 0);
    setIsUpload(volumes.length > 0 && enhanceVolumes.length > 0)
  }, [volumes.length, enhanceVolumes.length]);

  const enhanceImage = () => {
    setIsUpload(true);
    setIsImageEnhanced(true);
    fetch(`${API_BASE_URL}/mri-request/enhance-mri`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${apitoken}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to enhance image');
        }
        return response.json();
    })
    .then(data => {
            const parsedData = JSON.parse(data.data);
            if (parsedData.succes === "Image enhanced successfully") {
                Swal.fire({
                    icon: 'success',
                    title: 'Image Enhanced Successfully',
                    text: 'Wait for a minute to see enhanced images',
                });

                const NVRVolumeEnhanceImage = [
                    {
                        url: `${API_BASE_URL}/mri-request/view-enhance-mri/enhance.nii.gz?token=${apitoken}`,
                    },
                ];
                setEnhanceVolumes(NVRVolumeEnhanceImage);
            } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: parsedData.database
              });
            }
    })
    .catch(error => {
        console.error('Error enhancing image:', error);
        Swal.fire({
            icon: 'error',
            title: 'Server Corrupted',
            text: error.message
        });
    });
  };

  function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const clearElement = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    clearUploadedFile();
  };

  const clearUploadedFile = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/mri-request/delete-mri`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apitoken}`,
        },
      });
      if (response.ok) {
        console.log('MRI image deleted successfully');
        setVolumes([]);
        setEnhanceVolumes([]);
      } else {
        console.error('Failed to delete MRI image:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting MRI image:', error);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      <div className="d-flex justify-content-around"><span>{file.path}</span> <span className="ms-auto">{(file.size / (1024 * 1024)).toFixed(2)} mb</span></div>
    </li>
  ));

  const downloadEnhanceImage = () => {
    const url = `${API_BASE_URL}/mri-request/view-enhance-mri/enhance.nii.gz?token=${apitoken}`;
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'enhance.nii.gz');                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading the enhanced image:', error));
  };

  return (
    <div>
      <div className="home-container-bg"></div>
      <UserHeader />
      <div className="home-container" style={{ color: '#fff' }}>
        <div className="">
          <div className="tools w-23 p-4 d-none" id="enhancingTools">
            <div className='d-flex flex-column'>

              <div id="normalSlice">

                <h4 className='mb-3'>Normal Slices</h4>
                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Axial Slice</label>
                  <p className="mb-0 mb-xl-2">Select Axial  Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Coronal Slice</label>
                  <p className="mb-0 mb-xl-2">Select Coronal Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Sagittal Slice</label>
                  <p className="mb-0 mb-xl-2">Select Sagittal Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

              </div>

              <div id="enhanceSlice" className=''>

                <h4 className='my-3'>Enhance Slices</h4>
                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Sagittal Slice</label>
                  <p className="mb-0 mb-xl-2">Select Sagittal Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Coronal Slice</label>
                  <p className="mb-0 mb-xl-2">Select Coronal Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

                <div className="form-control mb-3">
                  <label htmlFor="customRange1" className="form-label fs-5">Axial Slice</label>
                  <p className="mb-0 mb-xl-2">Select Axial Slice</p>
                  <input type="range" className="form-range" id="" min="0" max="100" />
                </div>

              </div>

            </div>
          </div>
          <div className="home-content">

            <div className="container">
              <div className="py-md-4">

                <div className="">
                  <div className="d-flex mx-auto">
                    <div className="home-heading rounded-4 p-4 pt-1 w-100 main-bnr-text">
                      <h3 className="text-center text-light">Welcome To <br /> MRI Image Processing System</h3>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="w-md-50">
                          <input className="form-control dropFile form-control-lg" type="file" id="formFile" ref={fileInputRef} />
                          <p className="p-2">Please upload MRI image for enhancement.(format should be .nii)</p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mb-4">
                        <div className="me-3">
                          <button className="btn btn-1 btn-135" onClick={handleImageUpload} disabled={isUpload}>Upload <span><i className="fa fa-upload fs-5"></i></span> </button>
                        </div>
                        <div>
                          <button className="btn btn-1 btn-135" onClick={clearElement}>Clear</button>
                        </div>

                        {/* this is a demo button */}
                        <div className="ms-3"><button className="btn btn-1 btn-135" >Demo</button></div>
                      </div>

                    </div>
                  </div>
                  {volumes.length > 0 && <NiiVue volumes={volumes} />}
                  {volumes.length <= 0 && <NiiVue volumes={[]} loadingText="No Data Available, please upload file" />}
                  <div className="my-4">
                    <button className="btn btn-1 btn-135 mt-2" onClick={enhanceImage} disabled={isImageEnhanced}>Enhance image</button>
                  </div>
                  {enhanceVolumes.length > 0 && <NiiVueWrapper volumes={enhanceVolumes} />}
                  {enhanceVolumes.length <= 0 && <NiiVueWrapper volumes={[]} loadingText="No Data Available, please enhance file" />}

                  <div className="mt-3">
                    <button className="btn btn-1 btn-135 mt-2" onClick={downloadEnhanceImage}>Download Enhanced Image</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
