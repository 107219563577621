import React,{useEffect } from 'react';

import { Link } from 'react-router-dom'; // Import Link for routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NewHeader from './components/NewHeader';
import NewFooter from './components/NewFooter';
import './assets/new_css/animated-headline.css';
import './assets/new_css/style.css';
import './assets/new_css/bootstrap.min.css';
import '/src/assets/css/Newstyling.css';
import RadiologistImage from './assets/images/new_images/Radiologist1.webp';
const Radiologist = () => {

 
    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);

  return (
    <div>
      <NewHeader />
      {/* Slider Area */}
      <div className="slider-area2 radio_banner">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Radiologist</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}

      {/* About Section Start */}
      <div className="about-area section-padding2 ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-10">
              <div className="about-caption mb-50">
                {/* Section Title */}
                <div className="section-tittle  section-tittle2 mb-35">
                  {/* <span>About </span> */}
                  <h2 className="fs-2"> <i className="fa fa-chart-line"></i>  Accurate Measurements of Hippocampal Volume</h2>
                  <p>Alzevita provides precise and consistent hippocampal volume measurements, reducing the chances of human error. These dependable metrics help detect subtle structural changes in the brain. Such precision enhances both clinical decision-making and research accuracy.</p>
                  <h2 className="fs-2"><i className='fa fa-search'></i>  Supporting Early Diagnosis</h2>
                  <p>Hippocampal shrinkage is often one of the earliest signs of Alzheimer’s, appearing before symptoms emerge. Alzevita identifies these changes early, enabling prompt diagnosis. Early detection allows for timely treatments that may slow disease progression.</p>
                  <h2 className="fs-2"><i className='fas fa-sync-alt'></i>  Effective Monitoring of Alzheimer’s Disease Progression</h2>
                  <p>Alzevita measures hippocampal changes over time, delivering crucial insights into disease advancement. This data helps assess the effectiveness of treatments and informs personalised care strategies. Reliable monitoring supports better long-term disease management.</p>
                </div>
                {/* <div className="about-btn1 mb-30">
                  <Link to="/doctors" className="btn about-btn">
                    Find Doctors .<i className="ti-arrow-right"></i>
                  </Link>
                </div>
                <div className="about-btn1 mb-30">
                  <Link to="/appointment" className="btn about-btn2">
                    Appointment <i className="ti-arrow-right"></i>
                  </Link>
                </div>
                <div className="about-btn1 mb-30">
                  <Link to="/emergency" className="btn about-btn2">
                    Emergency 1 <i className="ti-arrow-right"></i>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {/* About Images */}
              <div className="about-img">
                <div className="about-font-img d-none d-lg-block">
                  <img src={RadiologistImage} alt="About us" className="img-fluid rounded-4 mx-4 my-5" />
                </div>
                <div className="about-back-img">
                  {/* <img src="/images/gallery/about1.png" alt="About us" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}
      <NewFooter />
    </div>
  );
};

export default Radiologist;
