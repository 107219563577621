import React from "react";
// import "../assets/css/your-styles.css"; // Adjust the path to your CSS if needed


const Pictures = () => {
  return (
    <section className="gallery-area section-padding30 back_img2">
      
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-tittle text-center mb-100">
              <span>Our Gallery</span>
              <h2>Images</h2>
            </div>
          </div>
        </div>

        {/* Gallery Rows */}

        {/* <div className="row"> */}
          {/* Left */}
          {/* <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img big-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery1.png)`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img small-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery2.png)`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img small-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery3.png)`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Right */}
          {/* <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img small-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery4.png)`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img small-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery5.png)`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-gallery mb-30">
                  <div
                    className="gallery-img big-img"
                    style={{
                      backgroundImage: `url(/images/gallery/gallery6.png)`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Pictures;
