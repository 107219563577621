import React, { useRef, useState } from 'react';
import './assets/css/OtpVerification.css';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OtpVerification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {  // Only allow digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent the default behavior
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleOtp = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');

    try {
      const response = await fetch(`${API_BASE_URL}/mri-users/verifyOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp: otpValue }),
      });

      if (response.ok) {
        const responseBody = await response.json();
        if (responseBody.msg === "User Verified") {
          toast.success('Otp Verified!');
          navigate('/login');
        } else {
          toast.error('Invalid Otp!');
        }
      } else {
        toast.error('Invalid Otp!');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  return (
    <div>
      <div className="signup-container"></div>
      <div className="container otp-form-container">
        <div className="p-md-5 p-3 w-lg-45 rounded-4" id="form-card">
          <div className="mb-4">
            <h1 className="text-center text-light mb-2">OTP Verification</h1>
            <p className="text-center text-light mb-4 fs-5">Welcome to MRI Image Processing System</p>
          </div>
          <div className="">
            <form className="otp-form" onSubmit={handleOtp}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-4">
                    <div className="form-group-input otp-form-group mb-3">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <input
                          key={index}
                          type="text"
                          id={`otp${index + 1}`}
                          name={`otp${index + 1}`}
                          minLength={1}
                          maxLength={1}
                          className="form-control form-control-lg border-2 otp-field"
                          value={otp[index]}
                          onChange={(e) => handleOtpChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => inputRefs.current[index] = el}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-primary w-50 rounded-4 py-2">Verify</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OtpVerification;
